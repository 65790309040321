import successFullPublish from "../assets/images/successFullPublish.svg";
import { black900, blue500 } from "../utility/colorCode";

const HSuccessfullyPublished = () => {
  return (
    <div className="d-flex justify-content-center align-item-center">
      <div>
        <div className="mb-2" style={{ textAlign: "center" }}>
          <img className="img-fluid" src={successFullPublish} alt="Hire Desk" />
        </div>
        <div className="">
          <h2
            style={{
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "28px",
              color: blue500,
              font: "Inter",
              textAlign: "center",
            }}
          >
            Successfully Published
          </h2>
          <p
            style={{
              fontWeight: "400",
              fontFamily: "Roboto",
              fontSize: "14px",
              color: black900,
              font: "Inter",
              textAlign: "center",
            }}
          >
            Your job post has been successfully submitted on the portal. Now{" "}
            <br />
            candidates can apply from the job portal.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HSuccessfullyPublished;
