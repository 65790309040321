const DateBadge = ({ type, date }: any) => {
  let bgColor = "";
  let color = "white";
  let stageActivity = "";

  switch (type) {
    case "applied":
      bgColor = "rgba(0, 0, 0, 0.08)";
      color = "black";
      stageActivity = "Applied";
      break;
    case "Shortlisted":
      bgColor = "#2E7D32";
      stageActivity = "Shortlisted";
      break;
    case "MovedToNext":
      bgColor = "#1976D2";
      stageActivity = "Moved To Next";
      break;
    case "declined":
      bgColor = "#FEEBEE";
      stageActivity = "Declined";
      break;
    default:
      bgColor = "";
      color = "black";
  }

  return (
    <div
      style={{
        padding: "2px 10px",
        borderRadius: "16px",
        background: bgColor,
        width: "fit-content",
        color: color,
        fontSize: "13px",
        fontWeight: "400",
        marginTop: "10px",
      }}
    >
      <div className="">{stageActivity && `${stageActivity} in ${date}`}</div>
    </div>
  );
};

export default DateBadge;
