import StepStatus from "./StepStatus";

const SliderContent = ({
  index,
  label,
  stepTitle,
  isProcessing,
  isCompleted,
}: any) => {
  return (
    <div className="d-flex align-items-center w-100">
      <StepStatus
        status={isProcessing ? "processing" : isCompleted ? "complete" : ""}
        index={index}
      />
      <div className="px-2 flex-grow-1 w-100">
        <p className="sub_title medium">{label}</p>
        <p style={{ fontSize: "12px" }} className="para_sm">
          {stepTitle}
        </p>
      </div>
    </div>
  );
};

export default SliderContent;
