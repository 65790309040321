import { Error } from "@mui/icons-material";

const HFieldError = (props: any) => {
  // console.log(props.children);
  return (
    <div
      className="error"
      style={{
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        width: "100%",
        marginTop: "5px",
        marginBottom: "0",
        textAlign: "left",
      }}
    >
      <Error sx={{ fontSize: "14px", position: "relative", top: "-1px" }} />{" "}
      {props.children}
    </div>
  );
};

export default HFieldError;
