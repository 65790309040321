import TextareaAutosize, { TextareaAutosizeProps } from "@mui/material/TextareaAutosize";
import HError from "./HError";

interface Props {
  librayName?: string;
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  height?: string;
  touched?: any;
  errors?: any;
  onChange?: any;
  value?: string | number;
  textareaAutosizeProps?: TextareaAutosizeProps
}

export default function HTextArea({
  librayName = "material",
  name,
  id,
  label = "",
  placeholder,
  height = "95px",
  value,
  onChange,
  errors,
  touched,
  textareaAutosizeProps

}: Props) {
  return (
    <div className="h_text_area_field">
      {librayName === "material" ? (
        <TextareaAutosize
          id={id}
          name={name}
          value={value}
          aria-label={label}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%", height: height, borderRadius: "4px" }}
          {...textareaAutosizeProps}

        />
      ) : null}
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
}
