import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import HError from "./HError";
interface PhoneNumberDDLPropsType {
  name?: string;
  countryFormat?: string;
  value: string | null;
  onChange: (phoneNumber: string) => void;
  inputClass?: string;
  dropdownClass?: string;
  searchClass?: string;
  enabledSearch?: boolean;
  touched?: { [key: string]: string };
  errors?: { [key: string]: string };
  specialLabel?: string;
  isRequired?: boolean;
}
const HSelectPhone = ({
  name,
  countryFormat,
  value,
  onChange,
  inputClass,
  dropdownClass,
  searchClass,
  enabledSearch,
  errors,
  touched,
  specialLabel,
  isRequired,
}: PhoneNumberDDLPropsType) => {
  const renderLabel: any = () => {
    if (isRequired) {
      return (
        <>
          {specialLabel || "Phone Number"}{" "}
          <span className="red-asterisk" style={{
            color: "red",
            fontSize: "12px",
          }}>*</span>
        </>
      );
    }
    return specialLabel || "Phone Number";
  };

  return (
    <div className="phone_input_style">
      <PhoneInput
        inputClass={inputClass}
        dropdownClass={dropdownClass}
        searchClass={searchClass}
        searchStyle={{ width: "94%" }}
        enableSearch={enabledSearch}
        country={countryFormat || "bd"}
        value={value}
        onChange={onChange}
        specialLabel={renderLabel()}
        autocompleteSearch
        inputProps={{
          required: isRequired,
        }}
      />
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
};

export default HSelectPhone;
