import { black06 } from "../utility/colorCode";

interface PropsType {
  title: string;
  description?: string;
  customText?: {
    text?: string;
    className?: string;
    onClick?: () => void;
  };
  titleStyle?: string;
  subTitleStyle?: string;
  isLightBlack?: boolean;
  isImage?: any;
  containerClass?:any;
}

const HHeadingDescription = ({
  containerClass,
  title,
  description,
  customText,
  titleStyle,
  subTitleStyle,
  isImage,
  isLightBlack = true,
}: PropsType) => {
  return (
    <div className={`d-flex flex-column ${containerClass || 'mb-4'}`}>
      <h3 className={titleStyle ? `${titleStyle}` : "h6"}>
        {isImage && isImage}
        {title}
      </h3>
      <p
        className={subTitleStyle ? `${subTitleStyle}` : "para_md"}
        style={{ color: isLightBlack ? black06 : "" }}
      >
        {description}
        {customText && (
          <span
            className={customText?.className}
            onClick={() => {
              customText?.onClick && customText?.onClick();
            }}
          >
            {customText?.text}
          </span>
        )}
      </p>
    </div>
  );
};

export default HHeadingDescription;
