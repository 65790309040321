import React from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { black400, black800, blue500 } from "../utility/colorCode";

function HRadio(props: any) {
  return (
    <>
      <FormControlLabel
        label={props?.label ? props?.label : ""}
        sx={{
          "&.MuiFormControlLabel-root .MuiTypography-root": {
            fontSize: props.labelFontSize || "14px",
            color: props.labelColor || black800,
            "&.Mui-disabled": {
              color: `${black400}!important`,
              opacity: 1,
            },
          },
        }}
        control={
          <Radio
            className={ props?.className || 'form-radio'}
            name={props?.name ? props?.name : ""}
            value={props?.value ? props?.value : ""}
            onChange={(e) => props?.onChange(e?.target?.value)}
            sx={{
              color: `${
                props?.styleObj?.color || props?.styleobj?.color || black800
              }!important`,
              "&.MuiRadio-root": {
                padding: props?.styleObj?.padding || props?.styleobj?.padding,
                "&.Mui-checked": {
                  color: `${
                    props?.styleObj?.checkedColor ||
                    props?.styleobj?.checkedColor ||
                    blue500
                  }!important`,
                  "&.Mui-disabled": {
                    color: `${black400}!important`,
                    opacity: 1,
                  },
                },
                "& .MuiSvgIcon-root": {
                  width:
                    props?.styleObj?.iconWidth ||
                    props?.styleobj?.iconWidth ||
                    "20px",
                  height:
                    props?.styleObj?.icoHeight ||
                    props?.styleobj?.icoHeight ||
                    "20px",
                },
              },
            }}
            disabled={props?.disabled ? props?.disabled : false}
          />
        }
        {...props}
      />
    </>
  );
}

export default HRadio;

/*
   <HRadio
      name="gender"
      label="Female"
      value={"female"}
      color={greenColor}
      onChange={(e) => {
         setFieldValue("gender", e.target.value);
      }}
      checked={values?.gender === "female"}
      disabled={true}
   />
*/
