import { Close } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import HIconTextInRow from "./HIconTextInRow";
// import HIconTextInRow from "./HIconTextInRow";
//
// all the props are required while using this component
// also maintain the data/props types
//  i have given demo component where this component has used....
// so that every one can have clear understanding about the component how it works.
// also you can direct copy the implementation and configure the implemantation
//
interface UploadProgressBarPropsType {
  open: boolean;
  fileName?: string;
  fileSize?: string;
  onClose?: () => void;
  uploadStatus: "error" | "success" | null;
  inputRef: HTMLInputElement | null;
  uploadPercentage: number;
  setUploadPercentage: Dispatch<SetStateAction<number>>;
  values?: any;
  setFieldValue?: any;
  setOpenProgressBar?: any;
}
const HProgressBar = ({
  open,
  fileName,
  fileSize,
  onClose,
  uploadStatus,
  inputRef,
  uploadPercentage,
  setUploadPercentage,
  setFieldValue,
  values,
  setOpenProgressBar,
}: UploadProgressBarPropsType) => {
  const [color, setColor] = useState<"primary" | "error">("primary");
  useEffect(() => {
    switch (uploadStatus) {
      case "error":
        setColor("error");
        setUploadPercentage(0);
        return;
      case "success":
        setColor("primary");
        setUploadPercentage(100);
        return;
      default:
        setColor("primary");
        setUploadPercentage(0);
        return;
    }
  }, [uploadStatus, setUploadPercentage]);

  return (
    <>
      {open ? (
        <div
          style={{
            width: "100%",
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
            padding: "16px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <p
              style={{
                color: "#1E293B",
                width: "100%",
                height: "26px",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              {fileName}
            </p>
            <div>
              <p>
                <p>{fileSize}</p>

                {values?.resume
                  ? "Complete"
                  : uploadStatus === "success" && uploadPercentage === 100
                    ? "Complete"
                    : uploadStatus === "error"
                      ? "Failed"
                      : "Loading"}
              </p>
            </div>
            <LinearProgress
              variant="determinate"
              color={color}
              value={uploadPercentage}
            />
          </div>
          <div style={{ marginLeft: "16px" }}>
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => {
                onClose && onClose();
                setUploadPercentage(0);
                if (inputRef?.value) inputRef.value = "";
                setFieldValue("resume", null);
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HProgressBar;

// while you want to use above HProgree component you must have to follow this fundamentals down bellow...

export const HUsingProgressBar = ({ props }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [openProgressBar, setOpenProgressBar] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<"error" | "success" | null>(
    null
  );
  const [uploadPercentage, setUploadPercentage] = useState<number>(
    props?.values?.resume ? 100 : 0
  );
  // const [file, setFile] = useState<any>(null);
  const {
    url,
    values,
    setFieldValue,
    touched,
    errors,
    isPdf,
    isUploadCVextAllowed,
  } = props;

  const uploadFile = async (files: any) => {
    setOpenProgressBar(true);
    setUploadStatus(null);
    const interval = setInterval(() => {
      setUploadPercentage((prev) => (prev !== 90 ? prev + 10 : prev));
    }, 250);

    let formData = new FormData();
    formData.append("files", files[0]);
    try {
      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          setFieldValue("resume", res?.data[0]);
        });

      if (isUploadCVextAllowed) {
        // cv upload_cv_full_text
        let textReadFormData = new FormData();
        textReadFormData.append("file", files[0]);
        const upload_cv_full_textUrl =
          "https://ocr.ibos.io/upload_cv_full_text";
        await axios
          .post(upload_cv_full_textUrl, textReadFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (res) => {
            function isAlphanumericOrSpace(char: string) {
              return /^[a-zA-Z0-9 ]$/.test(char);
            }

            function isSpecialCharacter(char: string) {
              const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '+', '=', '{', '}', '[', ']', ':', ';', '"', "'", '<', '>', ',', '.', '?', '/', '|', '\\'];
              return specialChars.includes(char);
            }

            function stripUnicode(str: string) {
              return str.split('').filter((x: string) => isSpecialCharacter(x) || isAlphanumericOrSpace(x)).join('');
            }

            setFieldValue(
              "keywordSummary", stripUnicode(res?.data?.CV_Text || "")
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }

      setUploadStatus("success");
      clearInterval(interval);
    } catch (error) {
      clearInterval(interval);
      setUploadStatus("error");
      toast.error("File Size is too large or inValid File!");
      return error;
    }
  };

  return (
    <div>
      <div>
        <label className="blue500 para_sm pointer">
          <HIconTextInRow
            icon={UploadFileIcon}
            text={"Click to upload"}
            iconClass={"mr-1 blue500 medium "}
            textClass={"para_sm medium  blue500 "}
          />
          <input
            name="resume"
            id="resume"
            ref={inputRef}
            hidden
            multiple
            type="file"
            // @ts-ignore
            touched={touched}
            errors={errors}
            onChange={(e) => {
              // pdf file validation
              const file = e.target.files?.[0];
              if (file) {
                if (isPdf && file.type !== "application/pdf") {
                  toast.error("Invalid file format. Please upload a PDF file.");
                  return;
                }
              }
              if (e.target.files?.[0]) {
                uploadFile(e.target.files);
              }
            }}
            accept={isPdf && "application/pdf"}
          />
        </label>
      </div>

      <HProgressBar
        open={values?.resume || openProgressBar}
        fileName={values?.resume?.fileName || "Please Wait..."}
        onClose={() => {
          setOpenProgressBar(false);
        }}
        uploadStatus={uploadStatus}
        inputRef={inputRef?.current}
        uploadPercentage={uploadPercentage}
        setUploadPercentage={setUploadPercentage}
        values={values}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

/* export const UsingProgressBar = ({ props = {} }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [openProgressBar, setOpenProgressBar] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<"error" | "success" | null>(
    null
  );
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [file, setFile] = useState<any>(null);
  const { url } = props;

  const uploadFile = async (files: any) => {
    setOpenProgressBar(true);
    setUploadStatus(null);
    const interval = setInterval(() => {
      setUploadPercentage((prev) => (prev !== 90 ? prev + 10 : prev));
    }, 250);

    let formData = new FormData();
    formData.append("files", files[0]);
    try {
      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => setFile(res?.data[0]));
      setUploadStatus("success");
      clearInterval(interval);
    } catch (error) {
      clearInterval(interval);
      setUploadStatus("error");
      toast.error("File Size is too large or inValid File!");
      return error;
    }
  };

  return (
    <div>
      <label className="blue500 para_sm pointer">
        <HIconTextInRow
          icon={UploadFileIcon}
          text={"Click to upload"}
          iconClass={"mr-1 blue500 medium "}
          textClass={"para_sm medium  blue500 "}
        />
        <input
          ref={inputRef}
          hidden
          multiple
          type="file"
          onChange={(e) => {
            if (e.target.files?.[0]) {
              uploadFile(e.target.files);
            }
          }}
        />
      </label>
      <HProgressBar
        open={openProgressBar}
        fileName={file ? file?.fileName : ""}
        onClose={() => {
          setOpenProgressBar(false);
        }}
        uploadStatus={uploadStatus}
        inputRef={inputRef?.current}
        uploadPercentage={uploadPercentage}
        setUploadPercentage={setUploadPercentage}
      />
    </div>
  );
}; */
