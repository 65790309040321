import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StepStatus = ({ status, index }: any) => {
  if (status === "processing")
    return (
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          textAlign: "center",
          fontSize: "12px",
          backgroundColor: "#1976D2",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="check flex-shrink-0"
      >
        {index}
      </div>
    );
  else if (status === "complete") {
    return (
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          textAlign: "center",
          fontSize: "12px",
          backgroundColor: "#1976D2",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="check flex-shrink-0"
      >
        <CheckCircleIcon sx={{ color: "#1976D2", backgroundColor: "white" }} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          textAlign: "center",
          fontSize: "12px",
          backgroundColor: "rgba(0, 0, 0, 0.38)",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="check flex-shrink-0"
      >
        {index}
      </div>
    );
  }
};

export default StepStatus;
