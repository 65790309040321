import { Divider } from "@mui/material";
import iBosLog from "../assets/logos/ibosPngLogo.png"

const HPoweredBy = () => {
  return (
    <>
      <Divider>
        {" "}
        <small className="text-secondary">Powered by</small>
      </Divider>
      <div className="text-center">
        <img src={iBosLog} alt="hire-desk-logo" width="200px" />
      </div>
    </>
  );
};

export default HPoweredBy;
