/* eslint-disable @typescript-eslint/no-unused-vars */
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import HButton from "./HButton";

type modalType = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: (e?: any) => void;
  size?: "sm" | "lg" | "xl";
  title?: string;
  backdrop?: boolean;
  fullscreen?:
    | true
    | string
    | "sm-down"
    | "md-down"
    | "lg-down"
    | "xl-down"
    | "xxl-down";
  isVisibleHeading?: boolean;
  isFooter?: boolean;
  classes?: string;
  buttonTitle1?: string;
  buttonTitle2?: string;
  backToHomeButton?: (e?: any) => void;
  cancelButton?: (e?: any) => void;
};
const HViewModal = ({
  obj,
  children,
}: {
  obj: modalType;
  children: ReactNode;
}) => {
  const {
    visible,
    setVisible,
    size,
    title,
    handleSubmit,
    backdrop = "static",
    classes,
    isVisibleHeading = false,
    fullscreen,
    isFooter = true,
    buttonTitle1,
    buttonTitle2,
    backToHomeButton,
    cancelButton,
  } = obj;
  return (
    <div className="viewModal">
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
        }}
        size={size}
        backdrop={backdrop}
        aria-labelledby="example-modal-sizes-title-xl"
        className={classes}
        fullscreen={fullscreen && fullscreen}
      >
        {isVisibleHeading && !backToHomeButton && !cancelButton && (
          <Modal.Header className="bg-custom">
            <Modal.Title className="text-center">{title}</Modal.Title>
            <div className="d-flex">
              <div className="mr-3">
                <HButton
                  title={buttonTitle2 ? buttonTitle2 : "Close"}
                  variant="outlined"
                  size="sm"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setVisible(false);
                  }}
                />
              </div>
              {handleSubmit && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Save"}
                    variant="contained"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSubmit(e);
                    }}
                  />
                </div>
              )}
            </div>
          </Modal.Header>
        )}

        {isVisibleHeading && !backToHomeButton && cancelButton && (
          <Modal.Header
            className="bg-custom"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="text-center h6"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              {title}
            </div>
            <div>
              <IconButton className="m-0 p-0">
                <Close
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setVisible(false);
                    cancelButton(e);
                  }}
                />
              </IconButton>
            </div>
          </Modal.Header>
        )}

        {isVisibleHeading && backToHomeButton && cancelButton && (
          <Modal.Header className="bg-custom" style={{ display: "block" }}>
            <div className="d-flex justify-content-between align-items-center px-5">
              {cancelButton && (
                <div>
                  <IconButton className="m-0 p-0">
                    <Close
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setVisible(false);
                        cancelButton(e);
                      }}
                    />
                  </IconButton>
                </div>
              )}
              {backToHomeButton && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Back to home"}
                    variant="text"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      backToHomeButton(e);
                    }}
                  />
                </div>
              )}
            </div>
          </Modal.Header>
        )}

        <Modal.Body id="example-modal-sizes-title-xl">{children}</Modal.Body>
        {isFooter && (
          <Modal.Footer>
            <div className="d-flex">
              <div className="mr-3">
                {cancelButton && (
                  <HButton
                    title={buttonTitle2 ? buttonTitle2 : "Close"}
                    variant="outlined"
                    size="md"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      cancelButton(e);
                    }}
                  />
                )}
              </div>
              {handleSubmit && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Save"}
                    variant="contained"
                    size="md"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSubmit(e);
                    }}
                  />
                </div>
              )}
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default HViewModal;
