import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HError from "./HError";

interface Props {
  variant?: "outlined" | "filled" | "standard";
  id?: string;
  size?: "small" | "medium";
  name: string;
  value?: any;
  label?: any;
  touched?: any;
  errors?: any;
  options: any;
  onChange: any;
  onBlur?: any;
  disabled?: boolean;
  styleObj?: any;
  required?: boolean;
}

export default function HSelectField({
  variant = "outlined",
  id,
  size = "medium",
  name,
  value = "",
  styleObj,
  label = "",
  options,
  onChange,
  errors,
  touched,
  disabled,
  onBlur,
  required = false,
}: Props) {
  const onChangeHandler = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;

    onChange(value ? value : "");
  };

  const inputFieldStyele = (type: string) => {
    switch (type) {
      case "standard":
        return {};
      case "filled":
        return {};
      default:
        return {
          padding: "16.5px 14px !important",
        };
    }
  };

  const customStyle = {
    "& .MuiInputBase-input": {
      ...inputFieldStyele(variant),
    },
  };

  return (
    <>
      <FormControl
        variant={variant}
        key={id}
        id={id}
        sx={{
          m: 0,
          minWidth: 120,
          "& .MuiInputLabel-asterisk": {
            color: (theme) => theme.palette.error.main,
            fontSize: "18px!important",
          },
        }}
        size={size}
        style={styleObj ? { ...styleObj, width: "100%" } : { width: "100%" }}
        error={errors[name] && touched[name] ? true : false}
        disabled={disabled}
        className="h_select-field"
        required={required}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          id={id}
          name={name}
          value={value}
          label={label}
          onChange={onChangeHandler}
          renderValue={(selected: any) => selected.label}
          onBlur={onBlur}
          sx={customStyle}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
        >
          {options?.length ? (
            options?.map((item: any, index: number) => {
              return (
                <MenuItem disabled={item.disabled} key={index} value={item}>
                  {item?.label}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="">
              <em>Loading...</em>
            </MenuItem>
          )}
        </Select>

        <HError errors={errors} touched={touched} name={name} />
      </FormControl>
    </>
  );
}
