import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { black400, black800, blue500 } from "../utility/colorCode";

function HCheckBox(props: any) {
  return (
    <>
      <FormGroup
        sx={{
          display: "inline-block",
        }}
      >
        <FormControlLabel
          sx={{
            "&.MuiFormControlLabel-root .MuiTypography-root": {
              fontSize: props.labelFontSize || "14px",
              color: props.labelColor || black800,
              "&.Mui-disabled": {
                color: `${black400}!important`,
                opacity: 1,
              },
            },
          }}
          control={
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
              }}
              name={props?.name}
              onChange={(e) => {
                props?.onChange(e.target.checked, e);
              }}
              checked={props?.checked ? props?.checked : false}
              disabled={props?.disabled ? props?.disabled : false}
              sx={{
                color: `${
                  props?.styleObj?.color || props?.styleobj?.color || black800
                }!important`,
                margin:
                  props?.styleObj?.margin ||
                  props?.styleobj?.margin ||
                  "0px !important",
                width: props?.styleObj?.width || props?.styleobj?.width,
                height: props?.styleObj?.height || props?.styleobj?.height,
                padding:
                  props?.styleObj?.padding || props?.styleobj?.padding || "9px",
                "&.Mui-checked": {
                  color: `${
                    props?.styleObj?.checkedColor ||
                    props?.styleobj?.checkedColor ||
                    blue500
                  }!important`,
                },
                "&.MuiCheckbox-root": {
                  "&.Mui-disabled": {
                    color: `${black400}!important`,
                    opacity: 1,
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
                "&.MuiCheckbox-root .MuiSvgIcon-root": {
                  height: props.height || "18px",
                },
              }}
            />
          }
          label={props?.label ? props?.label : ""}
        />
      </FormGroup>
    </>
  );
}

export default HCheckBox;

/*
   <HCheckBox
      styleObj={{
         margin: '0 auto!important',
         color: greenColor
      }}
      name="checkboxTwo"
      label="One"
      checked={values?.checkboxTwo}
      onChange={(value) => {
         setFieldValue("checkboxTwo", value);
      }}
      disabled={true}
   />
*/
