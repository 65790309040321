import { Popover } from "@mui/material";

const HPopOver = (props: any) => {
  const { id, anchorEl, setAnchorEl, styleObj = {} } = props;

  const open = Boolean(anchorEl);
  const popId = open ? id : undefined;

  return (
    <>
      <Popover
        sx={styleObj}
        onClick={(e: any) => e.stopPropagation()}
        id={popId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props?.children}
      </Popover>
    </>
  );
};

export default HPopOver;
