import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HTab = ({ tabLabel, componentToRender }: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginLeft: "-8px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          selectionFollowsFocus
        >
          {tabLabel?.map((item: any, index: number) => (
            <Tab
              icon={item?.icon && <item.icon />}
              iconPosition="start"
              key={index}
              label={item?.label}
              {...a11yProps(index)}
            />
          ))}
          <Divider />
        </Tabs>
      </Box>
      {componentToRender?.map((item: any, index: number) => {
        return (
          <TabPanel key={index} value={value} index={index} >
            <item.component />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default HTab;

// example
/* <HTab
  tabLabel={["Member", "Group"]}
  componentToRender={[{ component: Member }, { component: Group }]}
/>; */
