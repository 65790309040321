import { Box, TextField } from "@mui/material";
import { colorLess } from "../utility/colorCode";
import HError from "./HError";

interface Props {
  variant?: "outlined" | "filled" | "standard";
  name: string;
  // id?: string;
  label?: string;
  type?: "text" | "number" | "password" | "email" | "file" | "date" | "month";
  placeholder?: string;
  size?: "small" | "medium" | "large";
  touched?: any;
  errors?: any;
  onChange?: any;
  value?: any;
  maxLength?: any;
  inputstyle?: {};
  leadIcon?: any;
  disabled?: boolean;
}

export default function HIconTextField({
  variant = "outlined",
  name,
  // id = "",
  label = "",
  type = "text",
  placeholder = "",
  size = "medium",
  value,
  onChange,
  errors,
  touched,
  maxLength,
  inputstyle,
  leadIcon,
  disabled = false,
}: Props) {
  const customStyle = {
    width: "100% !important",
    "& .MuiInputLabel-filled": {
      top: "-3px",
      "&.Mui-focused": {
        top: "-5px!important",
      },
    },
    "& .MuiInputBase-input": {
      padding: "3.5px 8px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colorLess} !important`,
    },
  };
  return (
    <div className="h_input_field">
      {size === "small" || size === "medium" ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {leadIcon && leadIcon}
          {errors[name] && touched[name] ? (
            <TextField
              error
              variant={variant}
              name={name}
              label={label && label}
              type={type}
              placeholder={placeholder}
              size={size}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              color="primary"
              sx={customStyle}
              disabled={disabled}
              inputProps={{ maxLength, style: inputstyle }}
            />
          ) : (
            <TextField
              variant={variant}
              name={name}
              label={label}
              type={type}
              placeholder={placeholder}
              size={size}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              color="primary"
              sx={customStyle}
              disabled={disabled}
              inputProps={{ maxLength, style: inputstyle }}
            />
          )}
        </Box>
      ) : (
        <TextField
          variant={variant}
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          color="primary"
          sx={customStyle}
          disabled={disabled}
          inputProps={{ maxLength, style: inputstyle }}
        />
      )}
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
}
