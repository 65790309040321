/* eslint-disable no-sequences */
/* eslint-disable no-func-assign */

//@ts-nocheck
const sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd = require("crypto-js");
const configList: any[] = [
  'api/Auth/Login',
  'api/PeopleDeskDDL/PeopleDeskAllDDL',
  'api/Employee/GetRoleList',
  'api/MasterData/PeopleDeskAllLanding',
  'api/SaasMasterData/GetAllWorkplaceGroup',
  'api/Employee/CRUDEmployeeAllBasicInfo'
];
export function encryption(url: string) {
  for (const path of configList) {
    if (url.includes(path)) return true;
    continue;
  }
}



(function (_0x2cd084, _0x32d086) {
  const _0x4d2936 = _0xba6f,
    _0x5ea4a3 = _0x2cd084();
  while (!![]) {
    try {
      const _0x1730c3 =
        (parseInt(_0x4d2936(0x18c)) /
          (-0x3ed * -0x8 + -0x239 * 0x3 + -0x18bc)) *
          (-parseInt(_0x4d2936(0x175)) / (0x1d11 + 0x2700 + -0x1 * 0x440f)) +
        -parseInt(_0x4d2936(0x180)) / (0x8f8 + -0x40 * 0x18 + -0x1 * 0x2f5) +
        -parseInt(_0x4d2936(0x178)) /
          (0x3 * -0x2ad + -0x1622 * -0x1 + -0xe17 * 0x1) +
        (-parseInt(_0x4d2936(0x182)) / (0x111d * -0x1 + 0x830 + 0x8f2)) *
          (parseInt(_0x4d2936(0x17c)) / (0x1ffb + -0x56b + -0x1a8a)) +
        (parseInt(_0x4d2936(0x176)) / (0x1253 + 0x25d6 + -0x59d * 0xa)) *
          (-parseInt(_0x4d2936(0x181)) / (-0x143b + -0x1 * 0xade + 0x1f21)) +
        (-parseInt(_0x4d2936(0x188)) / (-0x5ca + 0x6fc + -0x129)) *
          (-parseInt(_0x4d2936(0x17a)) /
            (-0x63d * 0x1 + 0x3aa * 0x8 + 0x1 * -0x1709)) +
        parseInt(_0x4d2936(0x179)) / (0xef3 + 0x1edd + -0x2dc5);
      if (_0x1730c3 === _0x32d086) break;
      else _0x5ea4a3["push"](_0x5ea4a3["shift"]());
    } catch (_0x48f914) {
      _0x5ea4a3["push"](_0x5ea4a3["shift"]());
    }
  }
})(_0xab24, 0x154d * 0x1 + 0xb * 0x7340 + -0x16a6b);
function _0xba6f(_0x3c1529, _0x586fca) {
  const _0x4716df = _0xab24();
  return (
    (_0xba6f = function (_0x404229, _0x2c5239) {
      _0x404229 = _0x404229 - (-0x1d88 + 0x1c27 + -0x1 * -0x2d3);
      let _0x1f13bf = _0x4716df[_0x404229];
      return _0x1f13bf;
    }),
    _0xba6f(_0x3c1529, _0x586fca)
  );
}
export const _zx123_Zx001_45___45_9999_ = (_0x303352) => {
  const _0x240966 = _0xba6f,
    _0x5b0bb6 = {
      rISso: _0x240966(0x183),
      NLUzg: _0x240966(0x18b),
      dOAzv: function (_0x2ab3d0, _0x1a7613) {
        return _0x2ab3d0 + _0x1a7613;
      },
      ffAKd: function (_0xbfb3dd, _0x2edf26) {
        return _0xbfb3dd / _0x2edf26;
      },
    };
  let _0x506ca5 = _0x5b0bb6[_0x240966(0x17e)],
    _0x27b68d = _0x5b0bb6[_0x240966(0x17f)],
    _0x5123da = _0x5b0bb6[_0x240966(0x189)](
      _0x506ca5[_0x240966(0x186)](),
      _0x27b68d[_0x240966(0x186)]()
    );
  const _0x559f3c =
      sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
        _0x240966(0x187)
      ][_0x240966(0x17b)][_0x240966(0x184)](_0x5123da),
    _0x499d14 =
      sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
        _0x240966(0x187)
      ][_0x240966(0x17b)][_0x240966(0x184)](_0x5123da),
    _0x58f5a0 = sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
      _0x240966(0x173)
    ]
      [_0x240966(0x172)](
        sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
          _0x240966(0x187)
        ][_0x240966(0x17b)][_0x240966(0x184)](_0x303352),
        _0x559f3c,
        {
          keySize: _0x5b0bb6[_0x240966(0x174)](
            -0xc0e * 0x3 + -0x7 * 0x284 + 0x3646,
            -0x206f + -0x3d * 0x89 + 0x411c
          ),
          iv: _0x499d14,
          mode: sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
            _0x240966(0x185)
          ][_0x240966(0x18a)],
          padding:
            sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[
              _0x240966(0x177)
            ][_0x240966(0x17d)],
        }
      )
      [_0x240966(0x186)]();
  return _0x58f5a0;
};
function _0xab24() {
  const _0x22128a = [
    "rISso",
    "NLUzg",
    "536997GfJpqe",
    "1304808aYIuYI",
    "5FaCXsu",
    "@#%FWFJW#R",
    "parse",
    "mode",
    "toString",
    "enc",
    "3136563RqHxPA",
    "dOAzv",
    "CBC",
    "(%adfs",
    "11orfKbU",
    "encrypt",
    "AES",
    "ffAKd",
    "21354WfVnNT",
    "7sbOWWt",
    "pad",
    "1256840bBBiho",
    "9382439wMtVvP",
    "10KfpPqQ",
    "Utf8",
    "1142886kBannD",
    "Pkcs7",
  ];
  _0xab24 = function () {
    return _0x22128a;
  };
  return _0xab24();
}

(function (_0x185b36, _0x5c9f86) {
    const _0x593be6 = _0x24cb, _0x2a6281 = _0x185b36();
    while (!![]) {
        try {
            const _0x423f89 = -parseInt(_0x593be6(0x139)) / (-0x12fb * 0x1 + -0x161d + 0x2919) * (-parseInt(_0x593be6(0x146)) / (-0x6 * -0x564 + 0x3 * -0x6ec + -0xb92)) + -parseInt(_0x593be6(0x135)) / (-0x5d * -0x1a + -0x1d3a + -0x13cb * -0x1) * (-parseInt(_0x593be6(0x137)) / (-0x1d4d + 0x1f30 + 0x1 * -0x1df)) + -parseInt(_0x593be6(0x141)) / (0x3 * -0x8f0 + 0x244d + -0x978) + -parseInt(_0x593be6(0x130)) / (-0x2457 + 0x1 * -0xe1b + 0x2 * 0x193c) * (parseInt(_0x593be6(0x144)) / (-0x1a58 + 0x5 * 0x677 + -0x5f4)) + parseInt(_0x593be6(0x143)) / (-0x614 * -0x5 
+ -0x1dbf + -0x9d * 0x1) * (-parseInt(_0x593be6(0x13e)) / (0x75 * 0x5 + -0x1269 + 0x1 * 0x1029)) + parseInt(_0x593be6(0x142)) / (0xda3 + -0x699 + -0x700) + -parseInt(_0x593be6(0x13f)) / (-0xc0a + -0x2376 + 0x2f8b) * (-parseInt(_0x593be6(0x148)) / (0x7 * -0x3dd + 0x11 * 0x1af + -0x188));
            if (_0x423f89 === _0x5c9f86)
                break;
            else
                _0x2a6281['push'](_0x2a6281['shift']());
        } catch (_0x1bc028) {
            _0x2a6281['push'](_0x2a6281['shift']());
        }
    }
}(_0xb240, 0xd0f7 * 0xa + -0x3b * 0x343c + 0x1 * 0xd0f46));
export const _Ad_xcvbn_df__dfg_568_dfghfff_ = _0x31266c => {
    const _0x484c01 = _0x24cb, _0x2efaac = {
            'vAaco': _0x484c01(0x134),
            'LjjUw': _0x484c01(0x133),
            'ozTbj': function (_0x5f0bb1, _0x4f100c) {
                return _0x5f0bb1 + _0x4f100c;
            }
        };
    let _0x27d040 = _0x2efaac[_0x484c01(0x13b)], _0x58bb8e = _0x2efaac[_0x484c01(0x13d)], _0x582fb1 = _0x2efaac[_0x484c01(0x140)](_0x27d040[_0x484c01(0x131)](), _0x58bb8e[_0x484c01(0x131)]());
    const _0x2bf803 = sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x145)][_0x484c01(0x132)][_0x484c01(0x147)](_0x582fb1), _0x18bda4 = sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x145)][_0x484c01(0x132)][_0x484c01(0x147)](_0x582fb1), _0x175bba = sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x13c)][_0x484c01(0x12f)](_0x31266c, _0x2bf803, {
            'iv': _0x18bda4,
            'mode': sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x149)][_0x484c01(0x138)],
            'padding': sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x136)][_0x484c01(0x13a)]
        }), _0xc6de20 = _0x175bba[_0x484c01(0x131)](sdfghjksdfgh______859_____457___gfdgfdsgfdfdfdfd_fdkfkdf_dfd[_0x484c01(0x145)][_0x484c01(0x132)]);
    return JSON[_0x484c01(0x147)](_0xc6de20);
};
function _0x24cb(_0x40d7fe, _0x257807) {
    const _0x36990b = _0xb240();
    return _0x24cb = function (_0x17a5cb, _0x585242) {
        _0x17a5cb = _0x17a5cb - (0x1d52 + 0x15b2 + -0x31d5);
        let _0x5c7c6c = _0x36990b[_0x17a5cb];
        return _0x5c7c6c;
    }, _0x24cb(_0x40d7fe, _0x257807);
}
function _0xb240() {
    const _0x58af09 = [
        'toString',
        'Utf8',
        '(%adfs',
        '@#%FWFJW#R',
        '300LsCKjS',
        'pad',
        '17948WOzRYq',
        'CBC',
        '493175dWVMks',
        'Pkcs7',
        'vAaco',
        'AES',
        'LjjUw',
        '4827105ISbFWD',
        '22TlOwQC',
        'ozTbj',
        '4348800NAyNFS',
        '1994560eQrmvg',
        '8ayjJxN',
        '6563130bOlELd',
        'enc',
        '2FSjxDx',
        'parse',
        '10825464znKfCt',
        'mode',
        'decrypt',
        '6AtmadW'
    ];
    _0xb240 = function () {
        return _0x58af09;
    };
    return _0xb240();
}


export function encryptText(text) {
  const encoder = new TextEncoder();
  const passwordBytes = encoder.encode(text);
  const base64String = btoa(String.fromCharCode(...passwordBytes));
  return base64String;
}

export function decryptText(text) {
  try {
    const decodedString = atob(text);
    const bytes = new Uint8Array(
      [...decodedString].map((char) => char.charCodeAt(0))
    );
    const decoder = new TextDecoder();
    const password = decoder.decode(bytes);
    return password;
  } catch (e) {
    return "";
  }
}
