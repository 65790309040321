import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import HError from "./HError";

interface Props {
  variant?: "outlined" | "filled" | "standard";
  name: string;
  // id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  size?: "small" | "medium" | "large";
  touched?: any;
  errors?: any;
  onChange?: any;
  value?: any;
  maxLength?: any;
  inputstyle?: {};
}

const HPasswordField = ({
  variant = "outlined",
  name,
  // id = "",
  label = "",
  type = "password",
  placeholder = "",
  size = "small",
  value,
  onChange,
  errors,
  touched,
  maxLength,
  inputstyle,
}: Props) => {
  const [showPassword, setShowPass] = useState(false);

  // const customStyle = {
  //   width: "100% !important",
  // };
  const handleClickShowPassword = () => {
    setShowPass(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const customStyle2 = {
    width: "100% !important",
    "& .MuiInputLabel-filled": {
      top: "-3px",
      "&.Mui-focused": {
        top: "-5px!important",
      },
    },
    "& .MuiInputBase-input": {
      padding: "16.5px 14px !important",
    },
    "& .MuiOutlinedInput-notchedOutline legend": {
      width: "25% !important",
    },
  };
  return (
    <div>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          id="password"
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          // sx={customStyle}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={(e) => handleMouseDownPassword(e)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          sx={customStyle2}
          label="Password"
          color="primary"
        />
      </FormControl>
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
};

export default HPasswordField;
