import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

type Methods = "post" | "put" | "patch" | "delete" | "get";
type bool = true | false;

const useHireDeskReq = <T>() => {
  const [response, setResponse] = useState<T | any>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // api calling function
  const requestToServer = ({
    url,
    method,
    payload,
    cb,
    isToast = true,
    successMessage,
    errorMessage,
  }: reqDataType) => {
    setLoading && setLoading(true);
    axios({
      method: method ? method : "get",
      url: url,
      data: payload && payload,
    })
      .then((res: any) => {
        setResponse(res?.data);
        cb && cb(res?.data);
        setLoading(false);
        if (isToast) {
          toast.success(
            res?.data?.message ||
              res?.data?.Message ||
              successMessage ||
              "Submitted Successfully"
          );
        }
      })
      .catch((err: any) => {
        setResponse([]);
        setError(err);
        setLoading(false);
        if (isToast) {
          toast.warn(
            err?.response?.data?.message ||
              err?.response?.data?.Message ||
              errorMessage ||
              "Failed, try again"
          );
        }
      });
  };
  return [response, requestToServer, loading, setResponse, error] as const;
};

export default useHireDeskReq;
