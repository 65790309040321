import axios from "axios";
import { toast } from "react-toastify";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface ObjType {
  setFieldValue: (key: string, value: any) => void;
  accept?: string;
  cb?: (e: any) => void;
  setLoading: (b: boolean) => void;
  valueKey: string;
  refType: string;
  text: string;
  typeId: number;
  buId: number;
  userId: number;
  intAccountId: number;
}
const HUpload = ({ obj }: { obj: ObjType }) => {
  const {
    buId,
    cb,
    intAccountId,
    refType,
    setFieldValue,
    setLoading,
    text,
    typeId,
    userId,
    valueKey,
    accept,
  } = obj;
  const uploadFile = async (files: any) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("files", files[0]);
    let api: string = `/Document/UploadFile?accountId=${
      intAccountId || 0
    }&tableReferrence=${refType}&documentTypeId=${typeId}&businessUnitId=${buId}&createdBy=${
      userId || 0
    }`;
    try {
      const res = await axios.post(api, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.status === 200) {
        setLoading(false);
        setFieldValue(valueKey, res?.data[0]);
        cb && cb(res);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error("File Size is too large or inValid File!");
      return error;
    }
  };
  return (
    <label>
      <div
        className={`d-flex align-items-center`}
        style={{ cursor: "pointer" }}
      >
        <UploadFileIcon className="mr-1 blue500 medium" />
        <p className="para_sm medium  blue500 ">{text}</p>
        <input
          name="profileImageUrl"
          id="profileImageUrl"
          hidden
          multiple
          type="file"
          accept={
            accept
              ? accept
              : "image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          onChange={(e) => {
            // accept file types validation check
            const file = e.target.files?.[0];
            if (file) {
              const ext:any = file.name.split(".").pop();
              if (accept && !accept.includes(ext)) {
                toast.error("Invalid File Type");
                return;
              }
            }
            if (e.target.files?.[0]) {
              uploadFile(e.target.files);
            }
          }}
        />
      </div>
    </label>
  );
};

export default HUpload;
