import { Add, Remove } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import {
  black300,
  bluePrimary,
  colorLess,
  shadow08,
  shadowNone,
} from "../utility/colorCode";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface PropsType {
  title?: string;
  subTitle?: string;
  isIcon?: boolean;
  icon?: any;
  isAction?: boolean;
  index?: number;
  body?: any;
  onDeleteClick?: (e: any) => void;
  onEditClick?: (e: any) => void;
  styleType?: any;
  isDelete?: boolean;
  isEdit?: boolean;
  leadIcon?: boolean;
  tailIcon?: boolean;
  deleteBtnName?: string;
  saveBtnName?: string;
  isImage?: boolean;
  isImageClass?: string;
  filterIcon?: any;
  titleClass?: string;
  isDraggable?: boolean;
  isExpand?: any;
  hAccordionTitleParentStyle?: any;
  accordionTitleClass?: any;
  customStageDropProvided?: any;
  fixedIconClass?: any;
}

const HAccordion = ({
  filterIcon = false,
  titleClass,
  title,
  subTitle,
  isIcon,
  icon,
  isAction,
  onDeleteClick,
  onEditClick,
  index,
  body,
  styleType,
  isDelete = true,
  isEdit = true,
  leadIcon,
  tailIcon = true,
  deleteBtnName,
  saveBtnName,
  isImage = true,
  isImageClass,
  isDraggable = false,
  isExpand = null,
  fixedIconClass,
}: PropsType) => {
  const [expanded, setExpanded] = useState<boolean | string>(isExpand || false);
  const [isHovering, setIsHovering] = useState(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const styleObj = (type: string) => {
    switch (type) {
      case "jobCreate":
        return {
          width: "100%",
          boxShadow: "none",
          border: `1px solid ${black300} !important`,
          "& .MuiAccordionSummary-root": {
            borderRadius: `4px`,
          },
          "&.Mui-expanded": {
            transform: "rotate(0deg)",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            boxShadow: shadow08,
            border: `0px solid ${black300} !important`,
            borderLeft: `5px solid ${bluePrimary} !important`,

            "& .MuiAccordionSummary-root": {
              border: `0px solid #cbd5e1 !important`,
              borderRadius: `4px`,
            },
          },
        };
      case "filterMenu":
        return {
          width: "100%",
          boxShadow: "none",
          "& .MuiAccordionSummary-root": {
            borderTop: `1px solid #cbd5e1 !important`,
            minHeight: "40px",
          },
          "&.Mui-expanded": {
            transform: "rotate(0deg)",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            minHeight: "40px",
            "& .MuiAccordionSummary-root": {
              minHeight: "40px",
              borderBottom: `1px solid #cbd5e1 !important`,
            },
          },
        };
      case "stageAccordion":
        return {
          boxShadow: "none",
          "& .MuiAccordionSummary-root": {
            padding: "0px",
          },
          "& .MuiAccordionDetails-root": {
            padding: "0px !important",
          },
        };
      case "jobEvaluationMenu":
        return {
          width: "100%",
          boxShadow: "none",
          border: `1px solid ${colorLess} !important`,
          "& .MuiAccordionDetails-root": {
            padding: "0px !important",
          },
          "& .MuiAccordionSummary-root": {
            borderRadius: `4px`,
          },
          "&.Mui-expanded": {
            transform: "rotate(0deg)",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            boxShadow: shadowNone,
            border: `0px solid ${black300} !important`,
            borderLeft: `0px solid ${colorLess} !important`,

            "& .MuiAccordionSummary-root": {
              border: `0px solid #cbd5e1 !important`,
              borderRadius: `4px`,
            },
            "& .MuiAccordionDetails-root": {
              padding: "0px !important",
            },
          },
        };
    }
  };

  return (
    <div className="h_accordion">
      {isAction && (
        <ul className="h-accordion_list">
          {isDelete && (
            <li
              onClick={(e: any) => {
                onDeleteClick && onDeleteClick(e);
              }}
            >
              <span>{deleteBtnName || "Delete"}</span>
            </li>
          )}
          {isEdit && (
            <li
              onClick={(e: any) => {
                onEditClick && onEditClick(e);
              }}
            >
              <span>{saveBtnName || "Edit"}</span>
            </li>
          )}
        </ul>
      )}
      <Accordion
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        sx={styleObj(styleType)}
        onMouseOver={() => !expanded && setIsHovering(true)}
        onMouseOut={() => !expanded && setIsHovering(false)}
      >
        <AccordionSummary
          expandIcon={
            tailIcon ? (
              filterIcon ? (
                filterIcon
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : (
              false
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="h_accordion_header">
            {!expanded && isIcon && (
              <div
                className={
                  isImageClass
                    ? `h_accordion_icon ${isImageClass}`
                    : `h_accordion_icon`
                }
              >
                {isImage ? <img src={icon} alt="iBOS" /> : icon}
              </div>
            )}
            {expanded && isIcon && !isImage && (
              <div
                className={
                  isImageClass
                    ? `h_accordion_icon ${isImageClass}`
                    : `h_accordion_icon`
                }
              >
                {isImage ? <img src={icon} alt="iBOS" /> : icon}
              </div>
            )}
            <div className="h_accordion_title">
              <div>
                {expanded && (
                  <p className="para-lg medium d-flex align-items-center">
                    {leadIcon && (
                      <span className="accordion_lead_icon">
                        <Remove
                          className={fixedIconClass}
                          sx={{ fontSize: "16px" }}
                        />
                      </span>
                    )}
                    <span className={titleClass}>{title}</span>
                  </p>
                )}

                {!expanded && (
                  <div>
                    <p className="para-lg medium d-flex align-items-center">
                      {leadIcon && (
                        <span className="accordion_lead_icon">
                          <Add
                            className={fixedIconClass}
                            sx={{ fontSize: "18px" }}
                          />
                        </span>
                      )}
                      <span className={titleClass}>{title}</span>
                    </p>
                    <p className="para-sm">{subTitle}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!expanded && isDraggable && isHovering && (
            <DragHandleOutlinedIcon
              style={{
                position: "absolute",
                bottom: "0px",
                left: "50%",
                right: "50%",
                color: "#323232",
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          {body && <div className="h_accordion_body">{body}</div>}
          {expanded && isDraggable && isHovering && (
            <DragHandleOutlinedIcon
              style={{
                position: "absolute",
                bottom: "0px",
                left: "50%",
                right: "50%",
                color: "#323232",
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HAccordion;
