import { Box, IconButton, TextField } from "@mui/material";
import { black300, black500 } from "../utility/colorCode";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";

const HSearchField = ({
  classnames,
  onChange,
  placeholder,
  styleType,
  id,
  defaultValue,
  searchIconClick,
  onKeyPress
}: {
  classnames?: string;
  onChange: (e: any) => void;
  searchIconClick?: (e: any) => void;
  placeholder?: string;
  styleType?: any;
  id?: any;
  defaultValue?: string | null;
  onKeyPress?: (e: any) => void;
}) => {
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const handleInputChange = (e: any) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  const styleObj = (type: string) => {
    switch (type) {
      case "fixedApplied":
        return {
          width: "100%",
          p: "2px 3px",
          display: "flex",
          alignItems: "center",
          // border: `1px solid ${black300}`,
          borderRadius: "4px",
          height: "36px",
          backgroundColor: "rgba(0, 0, 0, 0.06)",
        };
      default:
        return {
          width: "100%",
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          border: `1px solid ${black300}`,
          borderRadius: "4px",
          height: "45px",
          backgroundColor: "white",
        };
    }
  };

  const searchIconStyleObj = (type: string) => {
    switch (type) {
      case "fixedApplied":
        return { p: "5px", color: `${black500}` };
      default:
        return { p: "10px", color: `${black500}` };
    }
  };

  return (
    <div>
      <Box className={classnames} sx={styleObj(styleType)}>
        {/* <IconButton
          type="button"
          sx={searchIconStyleObj(styleType)}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton> */}
        <TextField
          value={value || ""}
          id={id || "searchField"}
          fullWidth
          placeholder={
            placeholder ? placeholder : "Search job title, code, company"
          }
          sx={{
            "& fieldset": { border: "none" },
          }}
          onChange={handleInputChange}
          onKeyPress={onKeyPress}
        />{" "}
        <IconButton
          type="button"
          sx={searchIconStyleObj(styleType)}
          aria-label="search"
        >
          <SearchIcon onClick={searchIconClick} />
        </IconButton>
      </Box>
    </div>
  );
};

export default HSearchField;
