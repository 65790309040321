import moment from "moment";

export const todayDate = () => {
  const todayDate = moment(new Date()).format(`YYYY-MM-DDTHH:mm:ss`);
  return todayDate;
};

export const toBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);


export const increaseDecreaseDate = (
  modifiedDate: string,
  valueBy: number,
  isIncrease: boolean
) => {
  // function for customize any date
  // date: selected date to customize, no need to formatting
  // valueBy: int type value to decrease or increase value
  // isIncrease: if true date will be increase otherwise decrease
  // const modifiedDate = dateFormatter(date);
  const getDate = new Date(modifiedDate);
  if (!isIncrease) {
    getDate.setDate(getDate.getDate() - valueBy);
  } else {
    getDate.setDate(getDate.getDate() + valueBy);
  }
  return getDate;
};

export const stringToSeconds = (t: any) => {
  return (
    Number(t.split(":")[0]) * 60 * 60 * 1000 +
    Number(t.split(":")[1]) * 60 * 1000 +
    Number(t.split(":")[2]) * 1000
  );
};

export const timeFormatter = (time: any): any => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getTodayDateAndTime = () => {
  let today = new Date();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = todayDate() + "T" + time;
  return dateTime;
};
