type propType = {
  icon: any;
  text: string;
  textClass?: string;
  iconClass?: string;
  containerClass?: string;
  onClick?: () => void;
  containerStyle?: any;
  iconStyle?: any;
  textStyle?: any;
};

const HIconTextInRow = (props: propType) => {
  const {
    text,
    textClass = "",
    iconClass = "mr-1",
    onClick,
    containerClass = "",
    containerStyle = {},
    iconStyle = {},
    textStyle = {},
  } = props;
  return (
    <div
      className={`d-flex align-items-center ${containerClass}`}
      onClick={onClick}
      style={{ ...containerStyle, cursor: "pointer" }}
    >
      <props.icon className={iconClass} sx={iconStyle} />
      <p className={textClass} style={textStyle}>
        {text}
      </p>
    </div>
  );
};

export default HIconTextInRow;
