// @ts-nocheck
import React, { useRef, useEffect } from "react";

const HConfetti = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const colors = ["#ff0000", "#00ff00", "#0000ff"]; 

    function random(min, max) {
      return Math.random() * (max - min) + min;
    }

    function ConfettiPiece(x, y, color) {
      this.x = x;
      this.y = y;
      this.color = color;
      this.rotation = random(0, 2 * Math.PI);
      this.speed = random(2, 4);
      this.scale = random(0.5, 1.5);
    }

    ConfettiPiece.prototype.draw = function () {
      ctx.beginPath();
      ctx.fillStyle = this.color;
      ctx.moveTo(
        this.x + 5 * Math.cos(this.rotation),
        this.y + 5 * Math.sin(this.rotation)
      );
      ctx.lineTo(
        this.x + 5 * Math.cos(this.rotation + 2.4),
        this.y + 5 * Math.sin(this.rotation + 2.4)
      );
      ctx.lineTo(
        this.x + 5 * Math.cos(this.rotation + Math.PI),
        this.y + 5 * Math.sin(this.rotation + Math.PI)
      );
      ctx.closePath();
      ctx.fill();
    };

    const confettiPieces = [];

    for (let i = 0; i < 500; i++) {
      const x = random(0, canvas.width);
      const y = random(0, canvas.height);
      const color = colors[Math.floor(random(0, colors.length))];
      confettiPieces.push(new ConfettiPiece(x, y, color));
    }

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      confettiPieces.forEach((piece) => {
        piece.rotation += 0.05;
        piece.y += piece.speed;
        piece.draw();
      });

      requestAnimationFrame(draw);
    }
    draw();
    // Clean up
    return () => cancelAnimationFrame(draw);
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight -150}
    />
  );
};

export default HConfetti;
