const monthsShortName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dateFormatterReportDayFirst = (paramDate: string): string => {
  if (!paramDate) return "";
  const date = new Date(paramDate);
  const year = date?.getFullYear();
  const month = date?.getMonth();
  const shortMonth = `${monthNames[month]}`?.padStart(2, "0") + ",";
  const day = `${date?.getDate()}`;
  return [day, shortMonth, year]?.join(" ");
};

export const dateFormatterReport = (paramDate: string): string => {
  if (!paramDate) return "";
  const date = new Date(paramDate);
  const year = date?.getFullYear();
  const month = date?.getMonth();
  const shortMonth = `${monthNames[month]}`;
  const day = `${date?.getDate()}`?.padStart(2, "0") + ",";
  return [shortMonth, day, year]?.join(" ");
};

export const dateFormatter = (paramDate: any) : string => {
  if (!paramDate) return "";
  const date = new Date(paramDate);
  const year = date?.getFullYear();
  const month = date?.getMonth();
  const shortMonth = `${monthsShortName[month]},`;
  const day = `${date?.getDate()}`?.padStart(2, "0");
  return [day, shortMonth, year]?.join(" ");
};


export const dateFormatterForDashboard = () : string=> {
  const date = new Date();
  const day = date.getDay();
  const dayName = `${days[day]}`;
  const month = date?.getMonth();
  const monthName = `${monthNames[month]},`;
  const dateNumber = `${date?.getDate()},`?.padStart(2, "0");
  return [dateNumber, monthName, dayName]?.join(" ");
};

export const dateFormatterForInput = (param: Date) : string=> {
  const date = new Date(param);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  return [year, month, day].join("-") || "";
};

export const monthFirstDate = () : string=> {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  return dateFormatterForInput(firstDay);
};

export const monthLastDate = () : string => {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const lastDay = new Date(y, m + 1, 0);
  return dateFormatterForInput(lastDay);
};

export const getMonthwithYear = (month: any) : string=> {
  const value = month.split("-");
  let monthName = "";
  if (value[1] < 10) {
    monthName = monthNames[value[1].split("")[1] - 1];
  } else {
    monthName = monthNames[value[1] - 1];
  }
  const monthwithYear = `${monthName}, ${value[0]}`;
  return monthwithYear;
};

export const dateFormatterWithDay = (param: string) : string => {
  // date short
  const getWeek = (day: number): any => {
    if (day === 0) {
      return "Sun";
    } else if (day === 1) {
      return "Mon";
    } else if (day === 2) {
      return "Tue";
    } else if (day === 3) {
      return "Wed";
    } else if (day === 4) {
      return "Thu";
    } else if (day === 5) {
      return "Fri";
    } else if (day === 6) {
      return "Sat";
    }
  };

  const date = new Date(param);
  const day = date.getDay();
  const month = date?.getMonth();
  const year = date?.getFullYear();
  const monthName = `${monthNames[month]},`;
  const dateNumber = `${date?.getDate()},`?.padStart(2, "0");
  return `${[dateNumber, monthName, year]?.join(" ")} (${getWeek(day)})`;
};
export const dateReverse = (str: string): string => {
  return str.split("-").reverse().join("-");
};
