import { Button } from "@mui/material";
import {
  black100,
  black12,
  black200,
  black300,
  black400,
  black800,
  blue500,
  blue600,
  bluePrimary,
  colorLess,
  blue50,
  shadowFour,
  shadowTwo,
  whiteColor,
} from "../utility/colorCode";

interface PropsType {
  variant?: "text" | "contained" | "outlined";
  title: string;
  onClick?: any;
  size?: string;
  disabled?: boolean;
  isSubmitType?: boolean;
  isIcon?: boolean;
  icon?: any;
  isFullwidth?: boolean;
  btnStyleType?: string;
  buttonClass?: string;
}

const buttonSizeFunc = (buttonType?: string) => {
  switch (buttonType) {
    case "lg":
      return {
        fontSize: "16px",
        lineHeight: "25px",
        letterSpacing: "0.15px",
        padding: "8px 22px",
      };
    case "md":
      return {
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        padding: "6px 16px",
      };
    case "sm":
      return {
        fontSize: "12px",
        lineHeight: "22px",
        letterSpacing: "0.15px",
        padding: "4px 10px",
      };
    default:
      break;
  }
};

const buttonTypeFunc = (buttonType?: string, btnStyleType?: string) => {
  switch (buttonType) {
    case "contained":
      return {
        backgroundColor: blue500,
        color: whiteColor,
      };
    case "outlined":
      return {
        backgroundColor: colorLess,
        color: blue600,
        border: `1px solid ${blue600}`,
        boxShadow: "none",
      };
    case "text":
      if (btnStyleType === "blueText") {
        return {
          backgroundColor: colorLess,
          color: bluePrimary,
          border: `1px solid ${colorLess}`,
          boxShadow: "none",
        };
      } else {
        return {
          backgroundColor: colorLess,
          color: black800,
          border: `1px solid ${colorLess}`,
          boxShadow: "none",
        };
      }
    default:
      break;
  }
};

const buttonTypeHoverFunc = (buttonType?: string, btnStyleType?: string) => {
  switch (buttonType) {
    case "contained":
      return {
        backgroundColor: blue600,
        color: whiteColor,
      };
    case "outlined":
      return {
        backgroundColor: blue50,
        color: blue600,
        border: `1px solid ${blue600}`,
        boxShadow: "none",
      };
    case "text":
      if (btnStyleType === "blueText") {
        return {
          backgroundColor: colorLess,
          color: bluePrimary,
          border: `1px solid ${colorLess}`,
          boxShadow: "none",
        };
      } else {
        return {
          backgroundColor: black100,
          color: black800,
          border: `1px solid ${black100}`,
          boxShadow: "none",
        };
      }
    default:
      break;
  }
};

const buttonTypeDisabledFunc = (buttonType?: string, btnStyleType?: string) => {
  switch (buttonType) {
    case "contained":
      return {
        backgroundColor: black200,
        color: black400,
        border: 0,
        boxShadow: "none",
      };
    case "outlined":
      return {
        color: black400,
        border: `1px solid ${black200}`,
        boxShadow: "none",
      };
    case "tertiary":
      return {
        backgroundColor: colorLess,
        color: black400,
        border: `1px solid ${colorLess}`,
        boxShadow: "none",
      };

    case "text":
      if (btnStyleType === "grayText") {
        return {
          backgroundColor: black12,
          color: black300,
          border: `1px solid ${black12}`,
          boxShadow: "none",
        };
      } else {
        return {
          backgroundColor: colorLess,
          color: black400,
          border: `1px solid ${colorLess}`,
          boxShadow: "none",
        };
      }
    default:
      break;
  }
};

export default function HButton({
  variant = "contained",
  title,
  onClick,
  size = "md",
  disabled,
  isSubmitType = false,
  isIcon = false,
  icon,
  isFullwidth = false,
  btnStyleType,
  buttonClass,
}: PropsType) {
  return (
    <Button
      className={buttonClass}
      type={isSubmitType ? "submit" : "button"}
      variant={variant}
      onClick={(e) => onClick && onClick(e)}
      sx={{
        fontWeight: 700,
        width: isFullwidth ? "100%" : "auto",
        textTransform: "capitalize",
        boxShadow: shadowTwo,
        borderRadius: "4px",
        ...buttonSizeFunc(size),
        ...buttonTypeFunc(variant, btnStyleType),
        "&:hover": {
          boxShadow: shadowFour,
          ...buttonTypeHoverFunc(variant, btnStyleType),
        },
        "&.Mui-disabled": {
          ...buttonTypeDisabledFunc(variant, btnStyleType),
        },
      }}
      disabled={disabled}
      startIcon={isIcon && icon}
    >
      {title}
    </Button>
  );
}
