import { TextField } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  MobileDatePicker,
  MonthPicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import HError from "./HError";
interface HDatePickerPropsType {
  type?: "date" | "time" | "date-time" | "mobile-date" | "month";
  formate?: string;
  value: string | null;
  onChange: (date: string | null) => void;
  label?: string;
  className?: string;
  maxDate?: string;
  minDate?: string;
  touched?: any; //{ [key: string]: string };
  errors?: any; //{ [key: string]: string };
  name?: string;
  variant?: "outlined" | "standard" | "filled" | undefined;
  disabled?: any;
  required?: boolean;
  isRequired?: boolean;
}

const customStyle = {
  "& .MuiInputLabel-asterisk": {
    color: "red",
    fontSize: "1rem",
  },
};

const HDatePicker = ({
  type,
  formate,
  value,
  onChange,
  label,
  className,
  maxDate,
  minDate,
  errors,
  touched,
  name,
  variant = "outlined",
  disabled,
  required = false,
  isRequired,
}: HDatePickerPropsType) => {
  return (
    <div className="muDatePicker-custom">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {type === "date-time" ? (
          <DateTimePicker
            className={className || "h_date_field"}
            label={label}
            value={value}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            onChange={(value) => {
              value ? onChange(moment(value).format()) : onChange(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ ...customStyle, width: "100%", zIndex: 1000 }}
                error={
                  errors && touched && name && errors[name] && touched[name]
                    ? true
                    : false
                }
                required={required || isRequired}
              />
            )}
          />
        ) : type === "time" ? (
          <TimePicker
            className={className || "h_date_field"}
            label={label}
            value={value}
            onChange={(value) => {
              value ? onChange(moment(value).format()) : onChange(null);
            }}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                sx={customStyle}
                {...params}
                error={
                  errors && touched && name && errors[name] && touched[name]
                    ? true
                    : false
                }
              />
            )}
          />
        ) : type === "mobile-date" ? (
          <MobileDatePicker
            className={className || "h_date_field"}
            label={label}
            value={value}
            onChange={(value) => {
              value ? onChange(moment(value).format()) : onChange(null);
            }}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                sx={customStyle}
                {...params}
                error={
                  errors && touched && name && errors[name] && touched[name]
                    ? true
                    : false
                }
              />
            )}
            inputFormat={formate || "DD/MM/YYYY"}
          />
        ) : type === "month" ? (
          <MonthPicker
            onChange={(value) => {
              value ? onChange(moment(value).format()) : onChange(null);
            }}
            date={value}
            className={className || "h_date_field"}
          />
        ) : type === "date" || !type ? (
          <DatePicker
            className={className || "h_date_field"}
            label={label}
            value={value}
            onChange={(value) => {
              value
                ? onChange(moment(value).format(`YYYY-MM-DDT00:00:00`))
                : onChange(null);
            }}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                sx={customStyle}
                variant={variant}
                {...params}
                error={
                  errors && touched && name && errors[name] && touched[name]
                    ? true
                    : false
                }
                required={required || isRequired}
              />
            )}
            inputFormat={formate || "DD/MM/YYYY"}
          />
        ) : (
          <></>
        )}
      </LocalizationProvider>
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
};

export default HDatePicker;
