import React from "react";
import { black06 } from "../utility/colorCode";
interface PropsType {
  title: string;
  description?: string;
  customText?: {
    text?: string;
    className?: string;
    onClick?: () => void;
  };
  titleStyle?: string;
  subTitleStyle?: string;
  isLightBlack?: boolean;
  imageHTML?: any;
  containerClass?: any;
  descriptionStyle?: string;
  actionContainer?: any;
  subTitleStyle2?: string;
  subTitle2?: string;
}

const HIconHeadingDescription = ({
  containerClass,
  title,
  description,
  customText,
  titleStyle,
  subTitleStyle,
  imageHTML,
  isLightBlack = true,
  descriptionStyle,
  actionContainer,
  subTitleStyle2 = "",
  subTitle2 = "",
}: PropsType) => {
  return (
    <div className={`d-flex ${containerClass ? containerClass : ""}`}>
      <div>{imageHTML && imageHTML}</div>
      <div className={descriptionStyle ? `${descriptionStyle}` : "ml-3"}>
        <h3 className={titleStyle ? `${titleStyle}` : "h6"}>{title}</h3>
        <p
          className={subTitleStyle ? `${subTitleStyle}` : "para_md"}
          style={{ color: isLightBlack ? black06 : "" }}
        >
          {description && description}
          {customText && (
            <span
              className={customText?.className}
              onClick={() => {
                customText?.onClick && customText?.onClick();
              }}
            >
              {customText?.text}
            </span>
          )}
        </p>
        {subTitle2 ? (
          <p
            className={subTitleStyle2 ? `${subTitleStyle2}` : "para_sm"}
            style={{ color: isLightBlack ? black06 : "" }}
          >
            {subTitle2 && subTitle2}
          </p>
        ) : null}
      </div>
      {actionContainer && actionContainer}
    </div>
  );
};

export default HIconHeadingDescription;
