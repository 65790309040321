import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

function HInstallPrompt() {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallPromptVisible, setInstallPromptVisible] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Show the install button
      setInstallPromptVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setInstallPromptVisible(false);
      });
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    setInstallPromptVisible(false);
  };

  return (
    <div className="App">
      <Dialog
        fullScreen={fullScreen}
        open={isInstallPromptVisible}
        onClose={handleClose}
        aria-labelledby="install-dialog-title"
        aria-describedby="install-dialog-description"
      >
        <DialogTitle id="install-dialog-title">Install HireDesk App</DialogTitle>
        <DialogContent>
          <DialogContentText id="install-dialog-description">
            Do you want to install this app on your device for a better
            experience?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(null, "cancel");
            }}
            color="primary"
            type="button"
          >
            Cancel
          </Button>
          <Button onClick={handleInstallClick} color="primary" autoFocus>
            Install
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HInstallPrompt;
