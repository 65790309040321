import Chart from "react-apexcharts";

const HGraphChart = ({ chartDataObj, type, width, height }: any) => {
  return (
    <>
      <div className="radial-chart">
        <Chart
          options={chartDataObj?.options}
          series={chartDataObj?.series}
          type={type}
          height={height}
          width={width}
        />
      </div>
    </>
  );
};

export default HGraphChart;
