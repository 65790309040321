//@ts-ignore
import DOMPurify from "dompurify";

export function stripHtml(html: any): any {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}
export const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
  /* 
uses: 
 <div
dangerouslySetInnerHTML={createMarkup(
//@ts-ignore
resJobDetails.strJobDescription)}
>
</div>
*/
};
