import noResult from "../assets/images/noResult.png";
import { black300 } from "../utility/colorCode";

const HNoDataFound = ({ title, para }: { title?: string; para?: string }) => {
  return (
    <div className="d-flex justify-content-center align-item-center">
      <div>
        <div className="no-result-img mb-4 mt-5">
          <img className="img-fluid" src={noResult} alt="Hire Desk" />
        </div>
        <div className="no-result-txt">
          <h2
            style={{
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "28px",
              color: black300,
              font: "Inter",
              textAlign: "center",
            }}
          >
            {title ? title : "No data"}
          </h2>
          <p
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: black300,
              font: "Inter",
              textAlign: "center",
            }}
          >
            {para ? para : "No data has been entered yet"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HNoDataFound;
