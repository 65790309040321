import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
interface PropsType {
  title?: string;
  message?: string;
  yesAlertFunc?: () => void;
  noAlertFunc?: () => void;
  [k: string]: any;
}
type confirmProps = (prop: PropsType) => any;

const HConfirmModal: confirmProps = (props) => {
  const { title, message, yesAlertFunc, noAlertFunc, ...rest } = props;

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-body">
          <div style={{
            position: "absolute",
            top: "3px",
            right: "3px",

          }}>
            <IconButton className="m-0 p-1">
              <Close
                onClick={(e: any) => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            </IconButton>
          </div>
          <h1>{title}</h1>
          <p>{message}</p>
          <div className="react-confirm-alert-button-group">
            <button
              onClick={() => {
                yesAlertFunc && yesAlertFunc();
                onClose();
              }}
            >
              {props?.yesText || "Yes"}
            </button>
            <button
              onClick={() => {
                noAlertFunc && noAlertFunc();
                onClose();
              }}
            >
              {props?.noText || "No"}
            </button>
          </div>
        </div>
      );
    },
    ...rest,
  });
};

export default HConfirmModal;
