import { toast } from "react-toastify";
import { setCookie } from "../../utility/Cookies";
import * as requestFromServer from "./api";
import { authSlice } from "./slice";
import { setEmptyLocalStorage } from "commonRedux/reduxForLocalStorage/slice";
const { actions: slice }: any = authSlice;

export const setGoogleLoginAction =
  (payload: any, setLoading: any, navigate: any, cb?: (obj: any) => void) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .googleLoginApiCall(payload)
      .then((res) => {
        const {
          strLoginId,
          intAccountId,
          token,
          strDisplayName,
          intBusinessUnitId,
          strBusinessUnit,
          logoUrl,
          intEmployeeId,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
          isRecruiter,
        } = res?.data;
        let obj = {
          ...res?.data,
          strLoginId,
          userName: strDisplayName,
          orgId: intAccountId,
          email: strLoginId,
          token,
          buId: intBusinessUnitId,
          buName: strBusinessUnit,
          insertUserId: strLoginId,
          buLogo: logoUrl,
          employeeId: intEmployeeId,
          isRecruiter: isRecruiter,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
          isRegistration: intAccountId ? true : false,
        };
        setLoading(false);
        cb?.(obj);
        dispatch(slice.setLogin(obj));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Login failed, please try again"
        );
      });
  };

export const setMicrosoftLoginAction =
  (payload: LoginMicrosoftApiPayload, cb?: (obj: any) => void) =>
  //@ts-ignore
  (dispatch) => {
    return requestFromServer
      .microsoftLoginApiCall(payload)
      .then((res) => {
        const {
          strLoginId,
          intAccountId,
          token,
          strDisplayName,
          intBusinessUnitId,
          strBusinessUnit,
          logoUrl,
          intEmployeeId,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
          isRecruiter,
        } = res?.data;
        let obj = {
          ...res?.data,
          strLoginId,
          userName: strDisplayName,
          orgId: intAccountId,
          email: strLoginId,
          token,
          buId: intBusinessUnitId,
          buName: strBusinessUnit,
          insertUserId: strLoginId,
          buLogo: logoUrl,
          employeeId: intEmployeeId,
          isRecruiter: isRecruiter,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
          isRegistration: intAccountId ? true : false,
        };
        cb?.(obj);
        dispatch(slice.setLogin(obj));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "Login failed, please try again"
        );
      });
  };

export const setLoginAction =
  (
    email: string,
    password: string,
    urlId: any,
    navigate: any,
    setLoading: any,
    isRecruiter: boolean,
    isOAuth: boolean,
    creds: any,
    cb?: () => void
  ) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .loginApiCall(email, password, urlId, isRecruiter, isOAuth, creds)
      .then((res) => {
        const {
          strLoginId,
          intAccountId,
          token,
          strDisplayName,
          intBusinessUnitId,
          strBusinessUnit,
          logoUrl,
          intEmployeeId,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
        } = res?.data;
        let obj = {
          ...res?.data,
          strLoginId,
          userName: strDisplayName,
          orgId: intAccountId,
          email: strLoginId,
          token,
          buId: intBusinessUnitId,
          buName: strBusinessUnit,
          insertUserId: strLoginId,
          buLogo: logoUrl,
          employeeId: intEmployeeId,
          isRecruiter,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
          intProfileImageUrl,
          isRegistration: true,
        };
        if (res?.data?.isLoggedInWithOtp) {
          if (
            res?.data?.strOfficeMail ||
            res?.data?.strPersonalMail ||
            res?.data?.strLoginId
          ) {
            requestFromServer
              .getLoginOTP(
                res?.data?.strOfficeMail ||
                  res?.data?.strPersonalMail ||
                  res?.data?.strLoginId
              )
              .then((res) => {
                setLoading(false);
                obj = {
                  ...obj,
                  loginPin: res?.data?.message,
                };
                navigate("/verifypin", {
                  state: {
                    email:
                      obj?.strLoginId ||
                      obj?.strOfficeMail ||
                      obj?.strPersonalMail,
                    loginOTP: obj?.loginPin,
                    loginCB: cb ? cb : null,
                  },
                });
                dispatch(slice.setLogin(obj));
              })
              .catch((error) => {
                setLoading(false);
                toast.error("Login failed, please try again");
              });
          } else {
            setLoading(false);
            toast.warning("Please configure your email address!!!");
          }
        } else {
          setLoading(false);
          cb && cb();
          if (obj?.strFirstName || obj?.strLastName) {
            navigate("/jobList");
          } else {
            navigate("/auto-profile-fillUp");
          }
          dispatch(slice.setLogin(obj));
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Login failed, please try again"
        );
      });
  };

export const setAccountCreateAndUpdateAction2 =
  (payload: {}, navigate: any, setLoading: any, cb: any) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .AccountCreateNUpdate(payload)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          requestFromServer
            //@ts-ignore
            .getLoginOTP(payload?.strCompanyEmail)
            .then((res) => {
              setLoading(false);
              navigate("/verifypin", {
                state: {
                  //@ts-ignore
                  email: payload?.strCompanyEmail,
                  loginOTP: res?.data?.message,
                },
              });
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Login failed, please try again");
            });

          /* 
          //@ts-ignore
          setLoading(false);
          toast.success(`${res?.data?.message}` || "Created successfully"); */
          // cb && cb();
        } else {
          setLoading(false);
          navigate("/");
          toast.error("Registration failed, please try again");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.Message ||
            error?.response?.data?.message ||
            "Registration failed, please try again"
        );
      });
  };
interface CreateType {
  strOrganizationame: string;
  strFirstName: string;
  strLastName: string;
  strCompanyEmail: string;
  intCountryId: string | number;
  strMobileNumber: string | number;
  intBusinessCategoryId: string | number;
  strDomainName?: string;
  isActive: boolean;
  intAccountId: string | number;
  intUrlId: string | number;
  intCreatedBy: number;
  intUpdatedBy: number;
  userName: string;
  password: string;
  dteCreatedAt: string;
  dteUpdatedAt: string;
  [k: string]: any;
}
export const setAccountCreateAndUpdateAction =
  (payload: CreateType, navigate: any, setLoading: any, cb: any) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .getLoginOTP(payload?.strCompanyEmail)
      .then((res: any) => {
        setLoading(false);
        navigate("/verifypin", {
          state: {
            payload: payload,
            loginOTP: res?.data?.message,
            newemail: payload.strCompanyEmail,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Login failed, please try again");
      });
  };

export const setUserCreation2 =
  (payload: {}, navigate: any, setLoading: any, cb: any) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .UserCreation(payload)
      .then((res) => {
        const {
          strLoginId,
          intAccountId,
          token,
          strDisplayName,
          intBusinessUnitId,
          strBusinessUnit,
          logoUrl,
          intEmployeeId,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
        } = res?.data;
        let obj = {
          ...res?.data,
          strLoginId,
          userName: strDisplayName,
          orgId: intAccountId,
          email: strLoginId,
          token,
          buId: intBusinessUnitId,
          buName: strBusinessUnit,
          insertUserId: strLoginId,
          buLogo: logoUrl,
          employeeId: intEmployeeId,
          isLoggedInWithOtp,
          strOfficeMail,
          strPersonalMail,
        };
        if (obj) {
          if (res?.status === 200) {
            setLoading(false);
            toast.success("Created successfully");
            cb && cb();
          }
        } else {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.Message ||
            error?.response?.data?.message ||
            "Registration failed, please try again"
        );
      });
  };
export const setUserCreation =
  (
    payload: {
      strLoginId: string;
      [k: string]: any;
    },
    navigate: any,
    setLoading: any,
    cb: any
  ) =>
  //@ts-ignore
  (dispatch) => {
    setLoading(true);
    return requestFromServer
      .getLoginOTP(payload?.strLoginId)
      .then((res: any) => {
        setLoading(false);
        navigate("/verifypin", {
          state: {
            payload: payload,
            loginOTP: res?.data?.message,
            newemail: payload.strLoginId,
            isCandidateRegistration: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Login failed, please try again");
      });
  };

export const setLogoutAction = () => (dispatch: any) => {
  setCookie(
    "profileData",
    JSON.stringify({
      isAuth: false,
      isLoggedInWithOtp: false,
      isOtpAuth: false,
      loginPin: "",
    }),
    100
  );
  dispatch(slice.setLogout());
  dispatch(slice.setMenuList([]));
  dispatch(slice.setPermissionList([]));
  dispatch(slice.resetApplyWithProfile());
  dispatch(setEmptyLocalStorage());
};
export const jobApplyWithProfile =
  (isApply: boolean, payload?: { jobCode: string; isApply: boolean }) =>
  (dispatch: any) => {
    if (isApply && payload) return dispatch(slice.setApplyWithProfile(payload));
    dispatch(slice.resetApplyWithProfile());
  };
export const getMenuListAction =
  //@ts-ignore
  (intUserId: number, setLoading: any) => (dispatch) => {
    setLoading(true);
    requestFromServer
      .getMenuList(intUserId)
      .then((res) => {
        setLoading(false);
        dispatch(slice.setMenuList(res?.data));
      })
      .catch((error) => {
        setLoading(false);
        dispatch(slice.setMenuList([]));
      });
  };

//@ts-ignore
export const getPermissionListAction =
  //@ts-ignore
  (userId: number, setLoading: any) => (dispatch) => {
    setLoading && setLoading(true);
    requestFromServer
      .getPermissionList(userId)
      .then((res) => {
        setLoading && setLoading(false);
        dispatch(slice.setPermissionList(res?.data));
      })
      .catch((error) => {
        setLoading && setLoading(false);
        dispatch(slice.setPermissionList([]));
      });
  };

export const isOtpAuthAction = (data: any) => (dispatch: any) => {
  dispatch(slice.setIsOtpAuth(data));
};
export const setIsPublicAction = (isPublic: any) => (dispatch: any) => {
  dispatch(slice.setIsPublic(isPublic));
};

export const updateEmpProfilePicString = (id: any) => (dispatch: any) => {
  dispatch(slice.updateProPicString(id));
};
export const setNotifyCountAction = (data: any) => (dispatch: any) => {
  dispatch(slice.setNotifyCount(data));
};

export const getMsgNotificationCountAction =
  (accountId: any, userid: any, cb: any) => (dispatch: any) => {
    return requestFromServer
      .sendNotificationToUser(accountId, userid)
      .then((res: any) => {
        slice.setNotifyCount(res?.data || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

export const setUserOrganizationAction =
  (payload: any, setLoading: any, cb: any) => (dispatch: any) => {
    setLoading(true);
    return requestFromServer
      .getUserOrganization(payload)
      .then((res: any) => {
        const [msg, id] = res?.data?.split("|");
        dispatch(
          slice.setRegistration({
            isRegistration: true,
            orgId: id,
            intAccountId: id,
          })
        );
        setLoading(false);
        cb && cb(res?.data);
        toast.success(msg || "Account Create Successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.Message ||
            error?.response?.data?.message ||
            "Registration failed, please try again"
        );
      });
  };
