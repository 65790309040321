import { createSlice } from "@reduxjs/toolkit";

const initState = {
  profileData: {} as profileType,
  imageView: {},
  businessUnitDDL: [],
  menuList: [] as MenuList[],
  permissionList: [] as PermissionList[],
  userType: "Self",
  keywords: {},
  messageInfo: "",
  selectedUser: "",
  jobApply: {} as { jobCode: string; isApply: boolean },
  notifyCount: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setLogin: (state, action: any) => {
      const { payload } = action;
      state.profileData = { ...payload, isAuth: true, isOtpAuth: false };
    },
    setLogout: (state): any => {
      state.profileData = {} as profileType;
    },
    setMenuList: (state, action) => {
      const { payload } = action;
      state.menuList = payload;
    },
    setPermissionList: (state, action) => {
      const { payload } = action;
      state.permissionList = payload;
    },
    setIsOtpAuth: (state, action) => {
      const { payload } = action;
      state.profileData = payload;
    },
    updateProPicString: (state, action) => {
      const { payload } = action;
      state.profileData.intProfileImageUrl = payload;
    },
    setApplyWithProfile: (state, action) => {
      const { payload } = action;
      state.jobApply = payload;
    },
    setNotifyCount: (state, action) => {
      const { payload } = action;
      state.notifyCount = payload;
    },
    resetApplyWithProfile: (state) => {
      state.jobApply = {} as { jobCode: string; isApply: boolean };
    },
    setCandidateType: (state:any, action: any) => {
      const { payload }:any = action;
      state.profileData.employmentTypeId = payload?.employmentTypeId;
      state.profileData.employmentTypeName = payload?.employmentTypeName;
    },
    setIsTermsCondition: (state:any, action: any) => {
      const { payload }:any = action;
      state.profileData.isTermsCondition = payload?.isTermsCondition || false;
    },
    setIsPublic: (state, action: any) => {
      const { payload } = action;
      state.profileData.isPublic =  payload;
    },
    setRegistration: (state, action: any) => {
      const { payload } = action;
      state.profileData =  {
        ...state.profileData,
        ...payload,
      };
    },
  },
});
