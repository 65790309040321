export const whiteColor = "#ffffff";
export const blackColor = "#000000";

export const failColor = "#F04438";
export const failBg = "#FEE4E2";
export const warningColor = "#B54708";
export const warningBg = "#FEF0C7";
export const successColor = "#34a853";
export const successBg = "#E2FDE9";
export const progress = "#5925dc";
export const progressBg = "#ebe9fe";

export const disableColor = "rgba(0, 0, 0, 0.2)";
export const optionColor =
  "linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF";

// white
export const whiteColor60 = "rgba(255, 255, 255, 0.6)";

// black
export const black06 = "rgba(0, 0, 0, 0.6)";
export const black08 = "rgba(0,0,0,.08)";
export const black12 = "rgba(0,0,0,.12)";
export const black400 = "rgba(0, 0, 0, 0.42)";
export const black300 = "rgba(0, 0, 0, 0.23)";

export const black50 = "#f8fafc";
export const black100 = "#f1f5f9";
export const black200 = "#e2e8f0";
export const black500 = "#64748b";
export const black600 = "#475569";
export const black700 = "#334155";
export const black800 = "#1e293b";
export const black900 = "rgba(0, 0, 0, 0.87)";

/* blue */
export const bluePrimary = "#1976d2";
export const blueDark = "#1565c0";
export const blueLight = "#42a5f5";
export const blue50 = "#eff6ff";
export const blue100 = "#dbeafe";
export const blue200 = "#bfdbfe";
export const blue300 = "#93c5fd";
export const blue400 = "#42a5f5";
export const blue500 = "#1976d2";
export const blue600 = "#1565c0";
export const blue700 = "#1058a9";
export const blue800 = "#124d90";

/* green */
export const green30 = "rgba(46, 125, 50, 0.3)";
export const green50 = "#f0fdf4";
export const green100 = "#dcfce7";
export const green200 = "#bbf7d0";
export const green300 = "#86efac";
export const green400 = "#4ade80";
export const green500 = "#22c55e";
export const green600 = "#16a34a";
export const green700 = "#15803d";
export const green800 = "#166534";

// profile color 
export const Light700 = "#42A5F5";
// profile color end

export const divider = "#e0e0e0";

export const colorLess = "transparent";

export const iconColor = "#323232";

export const shadowOne =
  "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)";

export const shadowTwo =
  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)";

export const shadowThree =
  "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)";

export const shadowFour =
  "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)";

export const shadowFive =
  "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)";

export const shadowSix =
  "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)";

export const shadowSeven =
  "0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)";

export const shadow08 =
  "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)";

export const shadowNone = "none";
