import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
function useSignalRConnection() {
  const [signalRConnection, setSignalRConnection] = useState<any>(null);
  useEffect(() => {
    notificatonPermition();
  }, []);

  const notificatonPermition = async () => {
    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }
  };

  useEffect(() => {
    const connectionHub = new HubConnectionBuilder()
      .withUrl("https://signal.peopledesk.io/NotificationHub")
      .withAutomaticReconnect()
      .build();
    if (connectionHub) {
      connectionHub.start().then(() => {
        setSignalRConnection(connectionHub);
      });
    }
    return () => {
      connectionHub.stop();
    };
  }, []);

  return signalRConnection;
}

export default useSignalRConnection;
