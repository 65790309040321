import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { LocalStorageState } from "./type";

const initState: LocalStorageState = {
  appName: "HireDesk",
  jobDetailsRedux: {
    breadcrumbsUrl: "",
    breadcrumbsTitle: "",
    singleJob: "",
    stageType: "",
    stageTitle: "",
    assessmentCriteria: {},
  },
  recruiterUrlRedux: {
    urlId: "",
    accountId: "",
  },
  recentJobPostTypeInputValues: {},
  prvJobsListPostionObj: {},
  redirectJobApplyToLoginJobUrl: '',
};

export const localStorageSlice = createSlice({
  name: "localStorage",
  initialState: initState,
  reducers: {
    setAppName: (state, { payload }: PayloadAction<string>) => {
      state.appName = payload;
    },
    setJobDetailsRedux: (state, { payload }: PayloadAction<any>) => {
      state.jobDetailsRedux = payload;
    },
    setRecruiterUrlIdRedux: (state, { payload }: PayloadAction<any>) => {
      state.recruiterUrlRedux = payload;
    },
    setRecentJobPostTypeInputValues: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.recentJobPostTypeInputValues = payload;
    },
    setEmptyLocalStorage: (state) => {
      return initState;
    },
    setPrvJobsListPostionObj: (state, { payload }: PayloadAction<any>) => {
      state.prvJobsListPostionObj = payload;
    },
    setRedirectJobApplyToLoginJobUrl: (state, { payload }: PayloadAction<any>) => {
      state.redirectJobApplyToLoginJobUrl = payload;
    },
  },
});

// all state
export const selectAppName = (state: RootState) => state.localStorage.appName;
export const selectJobDetailsRedux = (state: RootState) =>
  state.localStorage.jobDetailsRedux;

export const selectRecruiterUrlIdRedux = (state: RootState) =>
  state.localStorage.recruiterUrlRedux;

export const selectredirectJobApplyToLoginJobUrl = (state: RootState) =>
  state.localStorage.redirectJobApplyToLoginJobUrl;
// all action
export const {
  setAppName,
  setJobDetailsRedux,
  setRecruiterUrlIdRedux,
  setRecentJobPostTypeInputValues,
  setEmptyLocalStorage,
  setPrvJobsListPostionObj,
  setRedirectJobApplyToLoginJobUrl
} = localStorageSlice.actions;
