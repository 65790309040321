import React, { lazy, Suspense } from "react"; // Import React.lazy and Suspense for lazy loading
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import Axios from "axios";
import "quill-mention";
import { toast, ToastContainer } from "react-toastify";
import { useAppSelector } from "./redux/hooks";
// import AuthPage from "./router/AuthPage";
// import BasePage from "./router/BasePages";
// import CandidatePage from "./router/CandidatePage";

import "react-quill/dist/quill.snow.css";
//css
import "bootstrap/dist/css/bootstrap.min.css";
import HInstallPrompt from "common/HInstallPrompt";
import { setNotifyCountAction } from "commonRedux/auth/action";
import { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { shallowEqual, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useSignalRConnection from "utility/customHooks/useSignalRConnection";
import {
  _Ad_xcvbn_df__dfg_568_dfghfff_,
  _zx123_Zx001_45___45_9999_,
  encryption,
} from "utility/encryption";
import { notify_KEY } from "utility/globalvariable";
import "./assets/css/index.css";
import { HLoading } from "common";
import ScrollToTopButton from "common/ScrollToTopButton";

// Lazy loading the components
const CandidatePage = lazy(() => import("./router/CandidatePage")); // Lazy load CandidatePage
const BasePage = lazy(() => import("./router/BasePages")); // Lazy load BasePage
const AuthPage = lazy(() => import("./router/AuthPage")); // Lazy load AuthPage

export const APIUrl =
  process.env.NODE_ENV === "development"
    ? "https://devhire.peopledesk.io/api"
    : `${window.location.origin}/api`;
Axios.defaults.baseURL = APIUrl;

export const domainUrl =
  process.env.NODE_ENV === "development"
    ? "https://devhire.peopledesk.io"
    : window.location.origin;
const hostname = window.location.hostname;

export const jobPostUrl = "https://job.hiredesk.work"

export const peopledeskUrl: string = [
  "localhost",
  "devarl.peopledesk.io",
].includes(hostname)
  ? "https://devarl.peopledesk.io"
  : "https://arl.peopledesk.io";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
Axios.defaults.headers.post["Content-Type"] = "application/json";

Axios.interceptors.request.use(
  async function (config) {
    let url: any = config.url;

    if (encryption(url)) {
      let newConfig = { ...config };
      const isIncludesQueryString = url.includes("?");
      if (isIncludesQueryString) {
        let splitUrl = url.split("?");
        const encryptedQuery = await _zx123_Zx001_45___45_9999_(splitUrl[1]);
        url = `${splitUrl[0]}?${encryptedQuery}`;
        newConfig = { ...config, url };
      }
      // make request payload encrypted
      let payload = null;
      if (config.data) {
        payload = await _zx123_Zx001_45___45_9999_(JSON.stringify(config.data));
      }
      newConfig = {
        ...newConfig,
        data: payload,
        headers: { ...newConfig.headers, "Content-Type": "application/json" },
      };
      return newConfig;
    } else {
      return config;
    }
  },
  async function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response: any) {
    let url: any = response?.config.url;
    if (encryption(url)) {
      let decryptedData = _Ad_xcvbn_df__dfg_568_dfghfff_(response?.data);
      let newData = {
        status: response.status,
        data: decryptedData,
      };
      return newData;
    } else {
      return response;
    }
  },
  async function (error) {
    return Promise.reject(error);
  }
);

function App() {
  const signalRConnection = useSignalRConnection();
  const {
    isAuth,
    isLoggedInWithOtp,
    isOtpAuth,
    isRecruiter,
    token,
    intUserId,
    intAccountId,
  } = useAppSelector((state) => state?.auth?.profileData, shallowEqual);
  const { notifyCount } = useAppSelector((state) => state?.auth, shallowEqual);
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const componentRender = () => {
    let isGeneralUser = true;
    if (isRecruiter) {
      isGeneralUser = false;
    }
    if (isLoggedInWithOtp) {
      if (isOtpAuth) {
        return isGeneralUser ? (
          <CandidatePage isCandidate={isGeneralUser} />
        ) : (
          <BasePage />
        );
      } else {
        return <AuthPage />;
      }
    } else {
      if (isAuth) {
        return isGeneralUser ? (
          <CandidatePage isCandidate={isGeneralUser} />
        ) : (
          <BasePage />
        );
      } else {
        return <AuthPage />;
      }
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = "90%";
    // testing disable incpect
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
    // document.addEventListener("keydown", (event) => {
    //   if (event.keyCode === 123) {
    //     event.preventDefault()
    //   } else if (
    //     (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
    //     (event.ctrlKey && event.shiftKey && event.keyCode === 74)
    //   ) {
    //     event.preventDefault()
    //   }
    // });
  }, []);

  const dispatch: any = useDispatch();

  useEffect(() => {
    if (signalRConnection) {
      console.log(signalRConnection, "signalRConnection");
      const notify_KEY_Str = notify_KEY(intAccountId, intUserId);
      console.log(notify_KEY_Str, "notify_KEY_Str");
      signalRConnection.on(`sendTo_${notify_KEY_Str}`, (count: any) => {
        console.log("count", count);
        dispatch(setNotifyCountAction(notifyCount + 1));
        if (count) {
          toast.info("A new notification has come!!", {
            autoClose: 2000,
            closeOnClick: true,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRConnection]);

  return (
    <div className="app">
      <HInstallPrompt />
      <Suspense fallback={<HLoading />}>
        {" "}
        {/* Fallback UI while components are loading */}
        {componentRender()}
      </Suspense>
      <ToastContainer
        position="bottom-right"
        newestOnTop={true}
        icon={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // limit={10}
        autoClose={2000}
      />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
