import { FormControlLabel, Switch } from "@mui/material";
import { black400, blue500, whiteColor } from "../utility/colorCode";

function HToggle(props: any) {
  return (
    <>
      <FormControlLabel
        label={props?.label ? props?.label : ""}
        control={
          <Switch
          
            onChange={(e)=>props?.onChange(e.target.checked)}
            checked={props?.checked ? props?.checked : false}
            name={props?.name ? props?.name : ""}
            disabled={props?.disabled ? props?.disabled : false}
            sx={{
              "& .MuiSwitch-thumb": {
                color: `${props?.color || whiteColor}!important`,
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: props?.color || blue500,
                "&.MuiSwitch-thumb": {
                  color: `${props?.color || blue500}!important`,
                },
                "&.Mui-disabled": {
                  color: props?.color || black400,
                },
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: props?.color || blue500,
              },
              "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
                backgroundColor: props?.color || blue500,
              },
            }}
          />
        }
        {...props}
      />
    </>
  );
}

export default HToggle;

/*
   <HToggle
      name="toggleTwo"
      label="Two"
      color={blueColor}
      checked={values?.toggleTwo}
      onChange={(e) => {
         setFieldValue("toggleTwo", e.target.checked);
      }}
      disabled={true}
   />
*/
