import { CircularProgress } from "@mui/material";

const HLoading = () => {
  return (
    <div>
      <h2 className="globalloading">
        {" "}
        <CircularProgress />
      </h2>
    </div>
  );
};

export default HLoading;
