import { Box, Step, StepLabel, Stepper } from "@mui/material";
import DateBadge from "modules/jobs/jobDetails/jobEvaluation/common/DateBadge";
import { dateFormatter } from "utility/dateFormatter";

const HEvaluationStepper = ({ stepData, orientation }: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{
          ".MuiStepLabel-root ": {
            display: "flex",
            alignItems: "flex-start !important",
          },
        }}
        activeStep={stepData?.filter((data: any) => data?.completed)?.length}
        orientation={orientation || "vertical"}
      >
        {stepData?.map((step: any, index: number) => (
          <Step key={index} completed={step?.completed}>
            <StepLabel>
              <p className="para_sm ">{step?.strStageName}</p>
              <p style={{ fontSize: "12px" }}>
                Recruitment stage type is {step?.strStageType}
              </p>
              <DateBadge
                type={index === 0 ? "applied" : step?.strFlag}
                date={dateFormatter(step?.dteActivityDate)}
              />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HEvaluationStepper;
