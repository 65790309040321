import { IconButton } from "@mui/material";

const HCircleButton = ({
  icon,
  title,
  subTitle,
  onClick,
}: {
  icon?: any;
  title?: string | number;
  subTitle?: string | number;
  onClick?: any;
}): JSX.Element => {
  return (
    <>
      <div className="circle_button">
        <IconButton className="circle_button_icon" onClick={onClick}>
          {icon}
        </IconButton>
        <div className="circle_button_txt">
          <h2>{title || "-"}</h2>
          <p>{subTitle}</p>
        </div>
      </div>
    </>
  );
};
export default HCircleButton;
