import { ArrowDropDown } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { blue500 } from "../utility/colorCode";

const HAccordionJobCard = ({
  title,
  subTitle,
  titleSize,
  icon,
  isAction,
  onDeleteClick,
  onEditClick,
  index,
  body,
  styleType,
  isJobCard,
  expanded,
  setExpanded,
  isCompleted,
  isShowStatus = false,
}: TAccordion) => {
  // const [expanded, setExpanded] = useState<boolean | string>(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const styleObj = (type: string) => {
    switch (type) {
      case "jobCard":
        return {
          width: "100%",
          boxShadow: "none",
          "& .MuiAccordionSummary-root": {
            height: "40px",
            padding: "0px 0px !important",
          },
          "&.Mui-expanded": {
            transform: "rotate(0deg)",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            "& .MuiAccordionSummary-root": {
              height: "40px",
            },
          },
        };
    }
  };
  return (
    <div className="h_accordion">
      <Accordion
        expanded={expanded === `panel`}
        onChange={handleChange(`panel`)}
        sx={styleObj(styleType)}
      >
        <AccordionSummary
          expandIcon={!isJobCard && <ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => handleChange(`panel`)}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div className="h_accordion_job_title">
              {icon ? (
                icon
              ) : (
                <PersonOutlineIcon className="h_accordion_customIcon" />
              )}
              <div>
                {isJobCard && (
                  <div>
                    <p className={titleSize ? titleSize : "para-lg bold"}>
                      {title}
                    </p>
                    <p className="para-sm">{subTitle}</p>
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", gap: "30px" }}>
              {isShowStatus && (
                <div className="">
                  <span
                    className={`job-status-tag ${
                      isCompleted ? "job-inProcess" : "job-saved"
                    }`}
                    style={{ fontSize: "13px" }}
                  >
                    {isCompleted ? "Completed" : "Not Start"}
                  </span>
                </div>
              )}
              <div style={{ color: blue500, fontWeight: 500 }}>
                <span>
                  {expanded ? (
                    <RemoveIcon className="job-card-font-icon" />
                  ) : (
                    <AddIcon className="job-card-font-icon" />
                  )}
                </span>
                <span className="ml-2">Expand</span>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {body && <div className="h_accordion_body">{body}</div>}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HAccordionJobCard;
