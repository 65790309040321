import { Avatar, Chip } from "@mui/material";
import { black600, black800, whiteColor } from "../utility/colorCode";
import { stringToColor } from "../utility/stringToColor";

interface PropsType {
  variant?: "filled" | "outlined";
  label: string;
  handleClick?: any;
  isAvater?: boolean;
  icon?: any;
  letterCount?: number;
  onDelete?: any;
  deleteIcon?: any;
  classes?: string;
  styles?: any;
  isAvatarBgColor?:any;
  size?:any;
}

export default function HChip({
  variant = "outlined",
  label,
  handleClick,
  isAvater = false,
  icon,
  letterCount,
  onDelete,
  deleteIcon,
  classes,
  styles,
  isAvatarBgColor =true,
  size
}: PropsType) {
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: isAvatarBgColor ? stringToColor(name) : "#78909C",
        fontSize: "10px",
      },
      children:
        letterCount === 2
          ? `${name?.split(" ")?.[0]?.[0]}${name?.split(" ")?.[1]?.[0]}`
          : `${name?.split(" ")?.[0]?.[0]}`,
    };
  };

  return isAvater ? (
    <Chip
      sx={{
        color:styles?.color || black800,
        fontWeight: 400,
        fontSize: "13px",
        border: styles?.bgColor ? "none" : `1px solid ${black600}`,
        padding: "6px 3px",
        backgroundColor: styles?.bgColor || whiteColor,
        ".MuiChip-avatar": {
          width: "24px!important",
          height: "24px!important",
          color: whiteColor,
          marginLeft: "0px",
        },
        ".MuiChip-deleteIcon": {
          color: styles?.color || `${black800} !important`,
          fontSize: styles?.fontSize || "13px!important",
          backgroundColor: styles?.iconBg || "",
          borderRadius: styles?.borderRadius || 0,
          padding: styles?.padding || "0",
        },
      }}
      label={label}
      variant={variant}
      onClick={handleClick}
      avatar={<Avatar {...stringAvatar(label)} />}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
      className={classes && classes}
    />
  ) : (
    <Chip
      sx={{
        color: black800,
        fontWeight: 400,
        fontSize: "13px",
        border: styles?.bgColor ? "none" : `1px solid ${black600}`,
        backgroundColor: styles?.bgColor || whiteColor,
        ".MuiChip-deleteIcon": {
          color: styles?.color || `${black800} !important`,
          fontSize: styles?.fontSize || "13px!important",
          backgroundColor: styles?.iconBg || "",
          borderRadius: styles?.borderRadius || 0,
          padding: styles?.padding || "0",
        },
      }}
      label={label}
      variant={variant}
      onClick={handleClick}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
      className={classes && classes}
      size={size && size}
    />
  );
}
