import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Dispatch, ReactNode, SetStateAction } from "react";
import HButton from "./HButton";

type modalType = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: (e?: any) => void;
  size?: "sm" | "lg" | "xl";
  title?: any;
  backdrop?: boolean;
  fullscreen?: true | false | undefined;
  isVisibleHeading?: boolean;
  isFooter?: boolean;
  classes?: string;
  buttonTitle1?: string;
  buttonTitle2?: string;
  backToHomeButton?: (e?: any) => void;
  cancelButton?: (e?: any) => void;
  scroll?: "paper" | "body" | undefined;
  customFooter?: any;
  customHeding?: any;
};
const HMaterialModal = ({
  obj,
  children,
}: {
  obj: modalType;
  children: ReactNode;
}) => {
  const {
    visible,
    setVisible,
    size,
    title,
    handleSubmit,
    classes,
    isVisibleHeading = false,
    fullscreen = false,
    isFooter = true,
    buttonTitle1,
    buttonTitle2,
    backToHomeButton,
    cancelButton,
    scroll,
    customFooter,
    customHeding,
  } = obj;

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div className="mui_modal_dialog">
      <Dialog
        sx={{ maxHeight: "546px" }}
        fullWidth
        fullScreen={fullscreen}
        maxWidth={size}
        open={visible}
        onClose={handleClose}
        scroll={scroll}
        className={classes}
      >
        {isVisibleHeading && customHeding && cancelButton && (
          <Box className="bg-custom d-flex justify-content-between mx-1 my-3">
            <div className="ml-3">{customHeding}</div>
            <div className="mr-3">
              <div>
                <IconButton className="m-0 p-0">
                  <Close
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      cancelButton(e);
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </Box>
        )}
        {isVisibleHeading && !backToHomeButton && !cancelButton && (
          <Box className="bg-custom">
            <DialogTitle className="text-center">{title}</DialogTitle>
            <div className="d-flex">
              <div className="mr-3">
                <HButton
                  title={buttonTitle2 ? buttonTitle2 : "Close"}
                  variant="outlined"
                  size="sm"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setVisible(false);
                  }}
                />
              </div>
              {handleSubmit && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Save"}
                    variant="contained"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSubmit(e);
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
        )}

        {isVisibleHeading && !backToHomeButton && cancelButton && !customHeding && (
          <DialogTitle
            className="bg-custom"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="text-center h6"
              style={{ color: "rgba(0, 0, 0, 0.87)" }}
            >
              {title}
            </div>
            <div>
              <IconButton className="m-0 p-0">
                <Close
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setVisible(false);
                    cancelButton(e);
                  }}
                />
              </IconButton>
            </div>
          </DialogTitle>
        )}

        {isVisibleHeading && backToHomeButton && cancelButton && (
          <Box className="bg-custom" style={{ display: "block" }}>
            <div className="d-flex justify-content-between align-items-center px-5">
              {cancelButton && (
                <div>
                  <IconButton className="m-0 p-0">
                    <Close
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setVisible(false);
                        cancelButton(e);
                      }}
                    />
                  </IconButton>
                </div>
              )}
              {backToHomeButton && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Back to home"}
                    variant="text"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      backToHomeButton(e);
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
        )}
        <DialogContent dividers id="example-modal-sizes-title-xl">
          {children}
        </DialogContent>
        {isFooter ? (
          customFooter && (
            <DialogActions className="d-flex justify-content-between">
              <div className="ml-3">{customFooter}</div>
              <div className="mr-3">
                {cancelButton && (
                  <HButton
                    title={buttonTitle2 ? buttonTitle2 : "Close"}
                    variant="outlined"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      cancelButton();
                    }}
                  />
                )}
              </div>
            </DialogActions>
          )
        ) : (
          <DialogActions>
            <div className="d-flex">
              <div className="mr-3">
                {cancelButton && (
                  <HButton
                    title={buttonTitle2 ? buttonTitle2 : "Close"}
                    variant="outlined"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setVisible(false);
                      cancelButton();
                    }}
                  />
                )}
              </div>
              {handleSubmit && (
                <div className="mr-2">
                  <HButton
                    title={buttonTitle1 ? buttonTitle1 : "Save"}
                    variant="contained"
                    size="sm"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleSubmit(e);
                    }}
                  />
                </div>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default HMaterialModal;
