import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { black800, iconColor } from "../utility/colorCode";

interface PropsType {
  title: string;
  onClick?: any;
}

export default function BackButton({ title, onClick }: PropsType) {
  const navigate = useNavigate();
  return (
    <div className="back_button_title">
      <IconButton onClick={onClick ? onClick : () => navigate(-1)}>
        <ArrowBack
          sx={{
            fontSize: "16px",
            color: iconColor,
          }}
        />
      </IconButton>
      <h3
        style={{
          color: black800,
          display: "inline-block",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "27px",
          marginLeft: "20px",
        }}
      >
        {title}
      </h3>
    </div>
  );
}
